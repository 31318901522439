import React, { memo } from "react";

import { PlanT, PrePeyDataT } from "../../types/subscriptionsTypes";
import styles from "./styles.module.scss";

import InfoIcon from "../../assets/images/information-button.svg";
import { isInt } from "../../helpers/functionsHelpers";

type PropsT = {
  data: PlanT;
  prePeyData: null | PrePeyDataT;
};

const SubscriptionPlanDetailsData: React.FC<PropsT> = memo(
  ({ data, prePeyData }) => {
    const totalSum = prePeyData
      ? prePeyData.prepaymentAmount + data.amount
      : data.amount;

    return (
      <div className={styles.main}>
        <div className={styles.block}>
          <div className={styles.topBlock}>
            <div className={styles.header}>
              <span>PAYMENT SUBSCRIPTIONS:</span>
              <div className={styles.tooltipWrapper}>
                <img src={InfoIcon} />
                <div className={styles.tooltip}>
                  <div className={styles.tooltipContainer}>
                    <div className={styles.tooltipArrow} />
                    <p>Subscription charged once a month. It is a</p>
                    <p>payment for Cleverfleet services and includes </p>
                    <p>contractor benefits payment also.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.body}>
              <div className={styles.line}>
                <span>Subscription</span>
                <span>{data?.name}</span>
              </div>
              <div className={styles.line}>
                <span>Sum</span>
                <span>{data.amountFormatted}</span>
              </div>
              <div className={styles.chargedLine}>
                <span>Charged</span>
                <span>{data.duration}</span>
              </div>
            </div>
          </div>
          {prePeyData ? (
            <div className={styles.bottomBlock}>
              <div className={styles.header}>
                <span>hold prepayment:</span>
                <div className={styles.tooltipWrapper}>
                  <img src={InfoIcon} />
                  <div className={styles.tooltip}>
                    <div className={styles.tooltipContainer}>
                      <div className={styles.tooltipArrow} />
                      <p>Prepayment is hold once and released when</p>
                      <p>the contract ends. It is needed to secure both</p>
                      <p>you and your contractor.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.body}>
                <div className={styles.line}>
                  <span>Contract</span>
                  <span>{prePeyData?.contractName}</span>
                </div>
                <div className={styles.line}>
                  <span>Contractor</span>
                  <span>{prePeyData?.contractorName}</span>
                </div>
                <div className={styles.line}>
                  <span>Sum</span>
                  <span>
                    {isInt(+prePeyData.prepaymentAmount)
                      ? `${prePeyData.prepaymentAmount}.00`
                      : prePeyData.prepaymentAmount}{" "}
                    {data?.currency === "usd" ? "USD" : "EUR"}
                  </span>
                </div>
                <div className={styles.chargedLine}>
                  <span>Charged</span>
                  <span>{prePeyData?.contractorCharged}</span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className={styles.totalSum}>
          Total sum: {data?.currency === "usd" ? "$" : "€"}
          {isInt(+totalSum) ? `${totalSum}.00` : totalSum}
        </div>
      </div>
    );
  }
);

export default SubscriptionPlanDetailsData;
