import { createSlice } from "@reduxjs/toolkit";
import {
  getMethods,
  paymentProcess,
  addPaymentMethod,
  removePaymentMethod,
  webhookSuccessRequest,
} from "../../actions/payment";
import { defaultState } from "./payments.defaultState";
import {
  PaymentMethod,
  PaymentProcessStateData,
  CardPaymentT,
} from "../../../types/paymentsTypes";

const paymentsSlice = createSlice({
  name: "payments",
  initialState: defaultState,
  reducers: {
    cleanRemoveCardState: (state, { payload }) => {
      state.removeCardState = {
        isLoading: false,
      };
    },
    cleanPaymentProcessState: (state) => {
      state.paymentProcessState = {
        isLoading: false,
      };
    },
    cleanAddPaymentMethodState: (state) => {
      state.addPaymentMethodState = {
        isLoading: false,
      };
    },
    cleanWebhookSuccessRequestState: (state) => {
      state.webhookSuccessRequestState = {
        isLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMethods.pending.toString(), (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getMethods.fulfilled.toString(),
      (
        state,
        action: { payload: { data: Array<PaymentMethod> }; type: string }
      ) => {
        state.isLoading = false;
        state.paymentMethods = action.payload.data;
      }
    );
    builder.addCase(getMethods.rejected.toString(), (state) => {
      state.isLoading = false;
    });
    builder.addCase(paymentProcess.pending.toString(), (state) => {
      state.paymentProcessState.isLoading = true;
    });
    builder.addCase(
      paymentProcess.fulfilled.toString(),
      (
        state,
        action: {
          payload: {
            isRedirect: boolean;
            data: PaymentProcessStateData;
            paymentMethodId?: string;
          };
          type: string;
        }
      ) => {
        state.paymentProcessState = {
          isLoading: false,
          success: true,
          data: action.payload.data,
          isRedirect: action.payload.isRedirect,
          paymentMethodId: action.payload.paymentMethodId,
        };
      }
    );
    builder.addCase(paymentProcess.rejected.toString(), (state) => {
      state.paymentProcessState = {
        isLoading: false,
        success: false,
      };
    });
    builder.addCase(addPaymentMethod.pending.toString(), (state) => {
      state.addPaymentMethodState.isLoading = true;
    });
    builder.addCase(
      addPaymentMethod.fulfilled.toString(),
      (
        state,
        action: {
          payload: { data: CardPaymentT; method?: string };
          type: string;
        }
      ) => {
        state.addPaymentMethodState = {
          success: true,
          isLoading: false,
          data: action.payload.data,
          method: action.payload.method,
        };
      }
    );
    builder.addCase(addPaymentMethod.rejected.toString(), (state) => {
      state.addPaymentMethodState = {
        success: false,
        isLoading: false,
      };
    });
    builder.addCase(removePaymentMethod.pending.toString(), (state) => {
      state.removeCardState.isLoading = true;
    });
    builder.addCase(
      removePaymentMethod.fulfilled.toString(),
      (state, action: { payload: { publicToken?: string }; type: string }) => {
        state.removeCardState = {
          success: true,
          isLoading: false,
        };
      }
    );
    builder.addCase(removePaymentMethod.rejected.toString(), (state) => {
      state.removeCardState = {
        success: false,
        isLoading: false,
      };
    });
    builder.addCase(webhookSuccessRequest.pending.toString(), (state) => {
      state.webhookSuccessRequestState = {
        isLoading: true,
      };
    });
    builder.addCase(webhookSuccessRequest.fulfilled.toString(), (state) => {
      state.webhookSuccessRequestState = {
        success: true,
        isLoading: false,
      };
    });
    builder.addCase(webhookSuccessRequest.rejected.toString(), (state) => {
      state.webhookSuccessRequestState = {
        success: false,
        isLoading: false,
      };
    });
  },
});
const paymentsReducer = paymentsSlice.reducer;

export const {
  cleanPaymentProcessState,
  cleanRemoveCardState,
  cleanAddPaymentMethodState,
  cleanWebhookSuccessRequestState,
} = paymentsSlice.actions;
export default paymentsReducer;
