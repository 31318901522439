import React from "react";
import styles from "./styles.module.scss";
import NoBankAccountIcon from "../../assets/images/Icon_no_bank_account.svg";

const NoBankAccount: React.FC<{}> = () => {
  return (
    <div className={styles.noBankAccount}>
      <p>Sorry, you don’t have any bank account.</p>
      <img src={NoBankAccountIcon} />
    </div>
  );
};

export default NoBankAccount;
