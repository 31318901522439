import React, { useState, useEffect } from "react";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Button from "../Button/Button";
import AddCardComponent from "../AddCardComponent/AddCardComponent";
import CardComponent from "../CardComponent/CardComponent";
import AddCardSuccess from "../AddCardSuccess/AddCardSuccess";

import { cleanAddPaymentMethodState } from "../../redux/reducers/payments/payments";
import { addPaymentMethod } from "../../redux/actions/payment";
import { setAlert } from "../../redux/reducers/alert/alert";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { payment_method_type_card } from "../../helpers/constants";
import { RootState } from "../../redux/store";
import { CardT } from "../../types/paymentsTypes";

import styles from "./styles.module.scss";

const AddCardPaymentInvoice: React.FC<{
  cardData?: CardT;
  onPayNewCardNavigate: () => void;
  onPayInvoice: (c: CardT) => void;
}> = ({ cardData, onPayNewCardNavigate, onPayInvoice }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [cardError, setError] = useState("");

  const { addPaymentMethodState } = useAppSelector(
    (state: RootState) => state.payments
  );

  const dispatch = useAppDispatch();

  const openSuccessModal = () => setIsSuccessModalOpen(true);
  const closeSuccessModal = () => {
    dispatch(cleanAddPaymentMethodState());
    setIsSuccessModalOpen(false);
  };

  useEffect(() => {
    if (
      addPaymentMethodState?.success &&
      addPaymentMethodState?.method === payment_method_type_card
    ) {
      openSuccessModal();
    }
  }, [addPaymentMethodState.success]);

  const onShowError = (error: string) => {
    dispatch(
      setAlert({
        type: "error",
        message: error,
      })
    );
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const card = elements?.getElement(CardNumberElement)!;

    const stripeData = await stripe?.createPaymentMethod({
      type: "card",
      card: card,
    });

    if (stripeData?.error?.message) {
      onShowError(stripeData.error.message);
      return;
    }

    stripeData.paymentMethod?.id &&
      dispatch(
        addPaymentMethod({
          payment_method_id: stripeData.paymentMethod?.id,
          method: payment_method_type_card,
        })
      );
  };

  let formComplete =
    cardNumberComplete &&
    cardCvcComplete &&
    cardExpiryComplete &&
    !cardError?.length;

  let submitButtonDisabled = !formComplete;

  return (
    <div className={styles.cardMainInvoicePayment}>
      {cardData ? (
        <>
          <CardComponent
            cardData={cardData}
            isShowSelectDefaultMethodButton={false}
          />
          <div className={styles.buttonPaymentInvoice}>
            <Button text="Apply" onClick={() => onPayInvoice(cardData)} />
            <div className={styles.buttonWithSmallMargin}>
              <Button
                text="Pay By New Card/Bank"
                variant="outlined"
                onClick={onPayNewCardNavigate}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <AddCardComponent
            setCardNumberComplete={setCardNumberComplete}
            setCardCvcComplete={setCardCvcComplete}
            setCardExpiryComplete={setCardExpiryComplete}
            setError={setError}
          />
          <div className={styles.buttonPaymentInvoice}>
            <Button
              text="Save Card"
              onClick={onSubmit}
              disabled={submitButtonDisabled}
            />
          </div>
        </>
      )}
      {isSuccessModalOpen ? (
        <AddCardSuccess
          closeSuccessModal={closeSuccessModal}
          text={"Your card has been successfully linked"}
        />
      ) : null}
    </div>
  );
};

export default AddCardPaymentInvoice;
