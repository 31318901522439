import React, { useState } from "react";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Button from "../Button/Button";
import AddCardComponent from "../AddCardComponent/AddCardComponent";
import CardComponent from "../CardComponent/CardComponent";
import PromoCodeInput from "../PromoCodeInput/PromoCodeInput";

import { addPaymentMethod } from "../../redux/actions/payment";
import { setAlert } from "../../redux/reducers/alert/alert";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { payment_method_type_card } from "../../helpers/constants";
import { RootState } from "../../redux/store";
import { CardT } from "../../types/paymentsTypes";

import styles from "./styles.module.scss";

const AddCardSubscriptionPay: React.FC<{
  cardData?: CardT;
  onBackNavigate: () => void;
  onPayForSubscription: (c: CardT) => void;
}> = ({ cardData, onBackNavigate, onPayForSubscription }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardError, setError] = useState("");

  const dispatch = useAppDispatch();

  const onShowError = (error: string) => {
    dispatch(
      setAlert({
        type: "error",
        message: error,
      })
    );
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const card = elements?.getElement(CardNumberElement)!;

    const stripeData = await stripe?.createPaymentMethod({
      type: "card",
      card: card,
    });

    if (stripeData?.error?.message) {
      onShowError(stripeData.error.message);
      return;
    }

    stripeData.paymentMethod?.id &&
      dispatch(
        addPaymentMethod({
          payment_method_id: stripeData.paymentMethod?.id,
          method: payment_method_type_card,
        })
      );
  };

  let formComplete =
    cardNumberComplete &&
    cardCvcComplete &&
    cardExpiryComplete &&
    !cardError?.length;

  let submitButtonDisabled = !formComplete;

  return (
    <div className={styles.cardMainInvoicePayment}>
      {cardData ? (
        <>
          <CardComponent
            cardData={cardData}
            isShowSelectDefaultMethodButton={false}
          />
          <div className={styles.buttonPaymentSubscription}>
            <Button
              text="Pay For The Subscription"
              onClick={() => onPayForSubscription(cardData)}
            />
            <div className={styles.buttonWithSmallMargin}>
              <Button
                text="Back To The Subscription"
                variant="outlined"
                onClick={onBackNavigate}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <AddCardComponent
            setCardNumberComplete={setCardNumberComplete}
            setCardCvcComplete={setCardCvcComplete}
            setCardExpiryComplete={setCardExpiryComplete}
            setError={setError}
          />
          <div className={styles.buttonPaymentSubscription}>
            <Button
              text="Save Card"
              onClick={onSubmit}
              disabled={submitButtonDisabled}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AddCardSubscriptionPay;
