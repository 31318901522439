import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

import BanksComponent from "../../Components/BanksComponent/BanksComponent";
import Loader from "../../Components/Loader/Loader";
import LoaderAbsolute from "../../Components/LoaderAbsolute/LoaderAbsolute";
import DeletePaymentMethodModal from "../../Components/DeletePaymentMethodModal/DeletePaymentMethodModal";
import CardsComponent from "../../Components/CardsComponent/CardsComponent";

import { stripeConfig } from "../../config";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/store";
import { getUserPaymentAccounts } from "../../redux/actions/user";
import { removePaymentMethod } from "../../redux/actions/payment";
import { DeletePaymentMethodParamsT } from "../../types/paymentsTypes";
import { payment_method_type_card } from "../../helpers/constants";

import BankLogo from "../../assets/images/Icon_bank.svg";
import styles from "./styles.module.scss";

//@ts-ignore
const stripePromise = loadStripe(stripeConfig.publicKey);

const AddPaymentMethod = () => {
  const dispatch = useAppDispatch();
  const { paymentsAccounts, isLoading } = useAppSelector(
    (state: RootState) => state.user
  );

  const { addPaymentMethodState } = useAppSelector(
    (state: RootState) => state.payments
  );

  const [open, setOpen] = useState(false);
  const [selectedPaymentMethodToDelete, setSelectedPaymentMethodToDelete] =
    useState<null | { title: string; payment_method_id: string }>(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(getUserPaymentAccounts());
  }, []);

  const onDeletePaymentMethodMenuClick = ({
    variant,
    payment_method_id,
  }: DeletePaymentMethodParamsT) => {
    setSelectedPaymentMethodToDelete({
      title:
        variant === payment_method_type_card
          ? "Are you sure you want to delete this card?"
          : "Are you sure you want to delete this bank account?",
      payment_method_id,
    });
    handleOpen();
  };

  const onConfirmDeletePaymentMethod = () => {
    if (selectedPaymentMethodToDelete) {
      dispatch(
        removePaymentMethod({
          payment_method_id: selectedPaymentMethodToDelete.payment_method_id,
        })
      );
    }
    handleClose();
  };

  return (
    <div className={styles.container}>
      {addPaymentMethodState?.isLoading ? <LoaderAbsolute /> : null}
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.cardsWrapper}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <img src={BankLogo} />
              <h2>Card payment</h2>
            </div>
            {stripePromise && (
              <CardsComponent
                onDeletePaymentMethodMenuClick={onDeletePaymentMethodMenuClick}
                savedCard={paymentsAccounts?.data?.card || []}
                stripePromise={stripePromise}
              />
            )}
          </div>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <img src={BankLogo} />
              <h2>Bank transfer</h2>
            </div>
            {stripePromise && (
              <BanksComponent
                savedBank={paymentsAccounts?.data?.bank_account || []}
                onDeletePaymentMethodMenuClick={onDeletePaymentMethodMenuClick}
                stripePromise={stripePromise}
              />
            )}
          </div>
        </div>
      )}
      <DeletePaymentMethodModal
        open={open}
        handleClose={handleClose}
        title={selectedPaymentMethodToDelete?.title || ""}
        onConfirm={onConfirmDeletePaymentMethod}
      />
    </div>
  );
};

export default AddPaymentMethod;
