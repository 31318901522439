import React from "react";
import { Button } from "@mui/material";

import OkIcon from "../../assets/images/Icon_ok.svg";
import styles from "./styles.module.scss";

const AddCardSuccess: React.FC<{
  closeSuccessModal: () => void;
  text: string;
}> = ({ closeSuccessModal, text }) => {
  return (
    <div className={styles.successModal}>
      <div className={styles.successModalBody}>
        <div className={styles.successModalHeader}>
          <Button
            className={styles.successModalCloseButton}
            onClick={closeSuccessModal}
          >
            <img src={require("../../assets/images/close.png")} />
          </Button>
        </div>
        <h5 className={styles.successModalTitle}>{text}</h5>
        <img src={OkIcon} className={styles.successModalLogo} />
      </div>
    </div>
  );
};

export default AddCardSuccess;
