const params = new URLSearchParams(window.location.search);
const testMode = params.get("testMode");

let isLive = testMode !== "true";

export const commonConfig = {
  apiHost: process.env.REACT_APP_API_HOST,
  bubbleHost: process.env.REACT_APP_BUBBLE_HOST,
  serverHost: process.env.REACT_APP_SERVER_HOST,
};

export const stripeConfig = {
  publicKey: isLive
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST,
  webhookApi: process.env.REACT_APP_WEBHOOK_API_LIVE,
};
