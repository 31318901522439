import React, { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import Button from "../Button/Button";
import DefaultMethodRadio from "../DefaultMethodRadioComponent/DefaultMethodRadioComponent";
import BankValues from "./BankValues";
import NoBankAccount from "./NoBankAccount";
import AddCardSuccess from "../AddCardSuccess/AddCardSuccess";
import AddBankAccount from "./AddBankAccount";

import { RootState } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setAlert } from "../../redux/reducers/alert/alert";
import { payment_method_type_bank } from "../../helpers/constants";
import { getEmailFromUrl } from "../../helpers/functionsHelpers";
import { cleanAddPaymentMethodState } from "../../redux/reducers/payments/payments";
import { setDefaultPaymentMethod } from "../../redux/actions/user";
import { setupIntent } from "../../redux/actions/subscriptions";
import { cleanPaymentIntentState } from "../../redux/reducers/subscriptions/subscriptions";
import { addPaymentMethod } from "../../redux/actions/payment";
import {
  BankT,
  StripeBankAccountResponse,
  PaymentMethodsVariantsT,
  DeletePaymentMethodParamsT,
} from "../../types/paymentsTypes";

import styles from "./styles.module.scss";

const AddBank: React.FC<{
  bankData?: BankT;
  isHideNoBankTitle?: boolean;
  addPaymentMethodInMenu: (v: PaymentMethodsVariantsT) => void;
  deletePaymentMethodInMenu: (params: DeletePaymentMethodParamsT) => void;
}> = ({
  bankData,
  isHideNoBankTitle,
  addPaymentMethodInMenu,
  deletePaymentMethodInMenu,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get("name") || "";

  const { paymentIntentState } = useAppSelector(
    (state: RootState) => state.subscriptions
  );
  const { addPaymentMethodState } = useAppSelector(
    (state: RootState) => state.payments
  );
  const { paymentsAccounts } = useAppSelector((state: RootState) => state.user);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const stripe = useStripe();
  const dispatch = useAppDispatch();

  const openSuccessModal = () => setIsSuccessModalOpen(true);
  const closeSuccessModal = () => {
    dispatch(cleanAddPaymentMethodState());
    setIsSuccessModalOpen(false);
  };

  useEffect(() => {
    return () => {
      onCloseModal();
    };
  }, [bankData]);

  useEffect(() => {
    if (
      addPaymentMethodState?.success &&
      addPaymentMethodState?.method === payment_method_type_bank
    ) {
      openSuccessModal();
    }
  }, [addPaymentMethodState.success]);

  useEffect(() => {
    if (paymentIntentState.data?.client_secret) {
      onGetPaymentIntent();
    }
  }, [paymentIntentState.data?.client_secret]);

  const onOpenModal = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    bankData && setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onShowError = (error: string) => {
    dispatch(
      setAlert({
        type: "error",
        message: error,
      })
    );
  };

  const onDefaultMethodChange = () => {
    if (bankData && !bankData?.is_default) {
      dispatch(setDefaultPaymentMethod({ payment_method_id: bankData?.id }));
    }
  };

  const onConnectBankAccount = () => {
    dispatch(setupIntent());
  };

  const onGetPaymentIntent = () => {
    stripe
      ?.collectBankAccountForSetup({
        clientSecret: paymentIntentState?.data?.client_secret || "",
        params: {
          payment_method_type: payment_method_type_bank,
          payment_method_data: {
            billing_details: {
              name,
              email: getEmailFromUrl(window.location.search),
            },
          },
        },
      })
      //@ts-ignore
      .then((result: StripeBankAccountResponse) => {
        if (result.error) {
          onShowError(result?.error?.message || "");
        } else if (result.setupIntent) {
          if (result.setupIntent.status === "requires_confirmation") {
            stripe
              .confirmUsBankAccountSetup(result.setupIntent.client_secret)
              //@ts-ignore
              .then((res: StripeBankAccountResponse) => {
                if (res.error) {
                  onShowError(res?.error?.message || "");
                } else if (res.setupIntent) {
                  onBankAddedSuccessful(res.setupIntent.payment_method);
                }
              });
          } else if (result.setupIntent.status === "succeeded") {
            onBankAddedSuccessful(result.setupIntent.payment_method);
          }
        }
      });
  };

  const onBankAddedSuccessful = (payment_method: string) => {
    dispatch(
      addPaymentMethod({
        payment_method_id: payment_method,
        method: payment_method_type_bank,
      })
    );
    dispatch(cleanPaymentIntentState());
  };

  const onCloseModals = () => {
    closeSuccessModal();
    onCloseModal();
  };

  return (
    <div className={styles.cardMain} onClick={onCloseModals}>
      <div className={styles.cardBodyHeader}>
        {isModalOpen ? (
          <div className={styles.menuContainer}>
            <div className={styles.menuCloseButton} onClick={onCloseModal}>
              <img src={require("../../assets/images/close.png")} />
            </div>
            <div
              className={styles.menuRow}
              onClick={() => addPaymentMethodInMenu(payment_method_type_bank)}
            >
              Add
            </div>
            <div className={styles.separator} />
            <div
              className={styles.menuRow}
              onClick={() => {
                onCloseModal();
                deletePaymentMethodInMenu({
                  variant: payment_method_type_bank,
                  payment_method_id: bankData?.id || "",
                });
              }}
            >
              Delete
            </div>
          </div>
        ) : (
          <div
            onClick={onOpenModal}
            className={bankData ? styles.menuActive : styles.menuHided}
          />
        )}
      </div>
      <div className={styles.cardBodyRow}>
        {bankData ? (
          <Grid
            container
            flexDirection={"column"}
            className={styles.bankAccount}
          >
            <Grid item className={styles.bankAccountItem}>
              <BankValues bankData={bankData} />
            </Grid>
            <Grid item className={styles.bankAccountItem}>
              <DefaultMethodRadio
                isDefault={bankData.is_default}
                onDefaultMethodChange={onDefaultMethodChange}
              />
            </Grid>
          </Grid>
        ) : (
          <div className={styles.noBankAccountWrapper}>
            {paymentsAccounts?.data?.bank_account?.length ? (
              <AddBankAccount />
            ) : (
              <NoBankAccount />
            )}
          </div>
        )}
        {isSuccessModalOpen ? (
          <AddCardSuccess
            closeSuccessModal={closeSuccessModal}
            text={"Your bank account has been added"}
          />
        ) : null}
      </div>
      {!bankData ? (
        <div className={styles.cardBodyFooter}>
          <Button text="Connect Bank Account" onClick={onConnectBankAccount} />
        </div>
      ) : null}
    </div>
  );
};

export default AddBank;
