import React, { useEffect, useState, useCallback } from "react";
import { Grid, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  getPlansList,
  updateActivePlan,
} from "../../redux/actions/subscriptions";
import { getActivePlanWithContractor } from "../../redux/actions/user";
import { setAlert } from "../../redux/reducers/alert/alert";
import { RootState } from "../../redux/store";
import { PlanT } from "../../types/subscriptionsTypes";

import SubscriptionPlan from "../../Components/SubscriptionPlan/SubscriptionPlan";
import LoaderAbsolute from "../../Components/LoaderAbsolute/LoaderAbsolute";
import PromoCodeInput from "../../Components/PromoCodeInput/PromoCodeInput";

import styles from "./styles.module.scss";
import history from "../../utils/history";

const Subscription = () => {
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(window.location.search);
  const testMode = params.get("testMode");
  const bearer = params.get("bearer") || "";
  const companyName = params.get("companyName") || "";
  const durationInMonth = params.get("durationInMonth") || "";
  const startDate = params.get("startDate") || "";
  const contractorId = params.get("contractorId") || "";
  const contractId = params.get("contractId") || "";
  const isOnlyViewSubscriptions = params.get("isOnlyViewSubscriptions") || "";
  const isUpdatePlan = params.get("isUpdatePlan") || "";
  const name = params.get("name");
  const email = params.get("email") || "";
  const userCurrency = params.get("userCurrency") || "usd";
  const prepaymentAmount = params.get("prepaymentAmount") || "";
  const contractName = params.get("contractName") || "";
  const contractorName = params.get("contractorName") || "";
  const contractorCharged = params.get("contractorCharged") || "";

  const { plansList, isLoading, updateActivePlanState } = useAppSelector(
    (state: RootState) => state.subscriptions
  );

  const { activeSubscription } = useAppSelector(
    (state: RootState) => state.user
  );

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>("");
  const [promoCodeValue, setPromoCodeValue] = useState<string | undefined>(
    undefined
  );

  const activeSubscriptionData = activeSubscription?.isInitiated
    ? Array.isArray(activeSubscription?.data)
      ? activeSubscription?.data[0]
      : activeSubscription?.data
    : null;

  useEffect(() => {
    if (!plansList?.length && userCurrency) {
      //@ts-ignore
      dispatch(getPlansList({ currency: userCurrency }));
    }
    if (isUpdatePlan) {
      if (contractorId && contractId) {
        dispatch(getActivePlanWithContractor({ contractorId, contractId }));
      } else {
        showError(
          "Missing all required parameters for getting active subscription"
        );
      }
    } else {
      if (!contractorId || !contractId) {
        showError(
          "Missing all required parameters for getting active subscription"
        );
      } else if (!startDate) {
        showError(
          "You have to choose contract start date before creating subscription."
        );
      }
    }
  }, []);

  const showError = (text: string) => {
    dispatch(
      setAlert({
        type: "error",
        message: text,
      })
    );
    setIsDisabled(true);
  };

  useEffect(() => {
    if (updateActivePlanState?.success) {
      history.push(
        `/success_screen?isUpdatePlan=${isUpdatePlan}&contractId=${contractId}&testMode=${testMode}`
      );
    }
  }, [updateActivePlanState?.success]);

  const onSubmitPlan = useCallback(
    (id: string, amount: string) => {
      if (
        isOnlyViewSubscriptions ||
        (isUpdatePlan && activeSubscriptionData?.planId === id)
      ) {
        return;
      }

      if (isUpdatePlan && activeSubscriptionData?.id) {
        dispatch(
          updateActivePlan({
            id: activeSubscriptionData.id,
            planId: id,
            promoCode: promoCodeValue,
          })
        );
      } else {
        history.push(
          `/subscription_details/${id}?bearer=${bearer}&companyName=${companyName}&durationInMonth=${durationInMonth}&startDate=${startDate}&contractorId=${contractorId}&contractId=${contractId}&amount=${
            amount || ""
          }&isUpdatePlan=${isUpdatePlan}&testMode=${testMode}&email=${email}&name=${name}&prepaymentAmount=${prepaymentAmount}&promoCodeValue=${
            promoCodeValue || ""
          }&contractorName=${contractorName}&contractorCharged=${contractorCharged}&contractName=${contractName}`
        );
      }
    },
    [activeSubscription, promoCodeValue]
  );

  return (
    <Grid className={styles.wrapper} container flexDirection="column">
      {(isLoading ||
        (isUpdatePlan &&
          (activeSubscription?.isLoading ||
            updateActivePlanState?.isLoading))) && <LoaderAbsolute />}
      <Grid item className={styles.container}>
        <Grid container flexDirection="column" className={styles.header}>
          <Grid item>
            <Typography variant="h1">Choose the subscription</Typography>
          </Grid>
          {!isOnlyViewSubscriptions ? (
            <Grid className={styles.promoCodeWrapper}>
              <PromoCodeInput
                value={promoCode}
                onValueChange={setPromoCode}
                onSetValue={setPromoCodeValue}
                defaultLogo={"If you have a promo code, you can enter it below"}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid container className={styles.plansWrapper}>
          {plansList?.map((plan) => (
            <SubscriptionPlan
              key={plan.id}
              data={plan}
              onSubmitPlan={onSubmitPlan}
              isOnlyViewSubscriptions={isOnlyViewSubscriptions === "true"}
              isDisabled={isDisabled}
              isUpdatePlan={!!isUpdatePlan}
              isCurrent={activeSubscriptionData?.planId === plan.id}
              futureStartDate={
                plan.id === activeSubscriptionData?.planId &&
                activeSubscriptionData?.status === "not_started"
                  ? activeSubscriptionData.startDate
                  : null
              }
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Subscription;
