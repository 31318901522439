import React from "react";
import { Grid, FormControlLabel, Radio } from "@mui/material";

import RadioIcon from "../../assets/images/radiobutton_active.svg";
import RadioNotActiveIcon from "../../assets/images/radiobutton.svg";
import styles from "./styles.module.scss";

type DefaultMethodRadioT = {
  isDefault?: boolean;
  onDefaultMethodChange: () => void;
};

const DefaultMethodRadio: React.FC<DefaultMethodRadioT> = ({
  isDefault,
  onDefaultMethodChange,
}) => {
  return (
    <Grid className={styles.defaultMethodFormWrapper}>
      <FormControlLabel
        checked={isDefault}
        value="default"
        control={
          <Radio
            onClick={onDefaultMethodChange}
            icon={<img src={RadioNotActiveIcon} />}
            checkedIcon={<img src={RadioIcon} />}
          />
        }
        label="Payment provider by default"
        className={styles.defaultMethodForm}
      />
    </Grid>
  );
};

export default DefaultMethodRadio;
