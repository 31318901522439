import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export type PropsType = {
  open: boolean;
  onClose: any;
  action: any;
  title?: string;
  text?: string;
  confirmButtonText?: string;
  color?: string;
};

const Confirm: React.FC<PropsType> = ({
  open,
  onClose,
  action,
  title,
  text,
  confirmButtonText,
  color,
}) => {
  const doConfirm = () => {
    action();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title ?? "Confirm"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text ?? "Are you sure?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="error">
          Cancel
        </Button>
        <Button onClick={doConfirm} color="primary" autoFocus>
          {confirmButtonText || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
