import React from "react";

const useOptions = (clientSecret?: string) => {
  const options = React.useMemo(
    () => ({
      clientSecret,
    }),
    [clientSecret]
  );

  return options;
};

export default useOptions;
