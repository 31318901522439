import React from "react";
import { Button } from "@mui/material";
import styles from "./styles.module.scss";
import ButtonBack from "../../assets/images/Button_back.svg";
import ButtonNext from "../../assets/images/Button_next.svg";

type ButtonPropsT = {
  isDisabled?: boolean;
  onClick: () => void;
  variant: "left" | "right";
};

const ArrowButton: React.FC<ButtonPropsT> = ({
  isDisabled,
  onClick,
  variant,
}) => {
  return (
    <Button
      className={`${styles.arrow} ${isDisabled && styles.arrowDisabled}`}
      onClick={onClick}
    >
      {variant === "left" ? <img src={ButtonBack} /> : <img src={ButtonNext} />}
    </Button>
  );
};

export default ArrowButton;
