import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Grid } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useOptions from "../../hooks/useOptions";

import { setAlert } from "../../redux/reducers/alert/alert";
import { paymentProcess } from "../../redux/actions/payment";
import { RootState } from "../../redux/store";
import { stripeConfig } from "../../config";

import PaymentMethod from "./PaymentMethod";
import LoaderAbsolute from "../../Components/LoaderAbsolute/LoaderAbsolute";

import styles from "./styles.module.scss";
import history from "../../utils/history";
//@ts-ignore
const stripePromise = loadStripe(stripeConfig.publicKey);

const NewCard = () => {
  const dispatch = useAppDispatch();

  const params = new URLSearchParams(window.location.search);
  const testMode = params.get("testMode");
  const amount = params.get("amount") || "";
  const companyName = params.get("companyName") || "";
  const invoiceId = params.get("invoiceId") || "";
  const planId = params.get("planId") || "";
  const startDate = params.get("startDate") || "";
  const durationInMonth = params.get("durationInMonth") || "";
  const contractorId = params.get("contractorId") || "";
  const contractId = params.get("contractId") || "";
  const userCurrency = params.get("userCurrency") || "";
  const invoiceCurrency = params.get("invoiceCurrency") || "";
  const prepaymentAmount = params.get("prepaymentAmount") || "";

  const { isLoading } = useAppSelector((state: RootState) => state.user);

  const { paymentProcessState, removeCardState, addPaymentMethodState } =
    useAppSelector((state: RootState) => state.payments);

  const { buySubscriptionState } = useAppSelector(
    (state: RootState) => state.subscriptions
  );

  let options = useOptions(`${paymentProcessState.data?.client_secret}`);

  useEffect(() => {
    if (buySubscriptionState?.success) {
      history.push(
        `/success_screen?contractId=${contractId}&testMode=${testMode}`
      );
    }
  }, [buySubscriptionState?.success]);

  useEffect(() => {
    if (amount && invoiceId) {
      dispatch(
        paymentProcess({
          amount,
          userCurrency,
          invoiceCurrency,
          invoiceId,
          type: "payment",
        })
      );
    } else {
      dispatch(
        setAlert({
          type: "error",
          message: "missing all required parameters",
        })
      );
    }
  }, [amount, companyName, dispatch, invoiceId, planId]);

  return (
    <Grid className={styles.stripeWrapper}>
      {stripePromise && options && paymentProcessState.data?.client_secret && (
        <Elements stripe={stripePromise} options={options}>
          <PaymentMethod
            planId={planId}
            startDate={startDate}
            durationInMonth={durationInMonth}
            contractorId={contractorId}
            contractId={contractId}
            prepaymentAmount={prepaymentAmount}
          />
        </Elements>
      )}
      {addPaymentMethodState?.isLoading ||
      isLoading ||
      paymentProcessState.isLoading ||
      buySubscriptionState?.isLoading ||
      removeCardState.isLoading ? (
        <LoaderAbsolute />
      ) : null}
    </Grid>
  );
};

export default NewCard;
