import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  TablePagination,
  TableBody,
  Table,
  Paper,
  Button,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  cancelSubscription,
  getAllSubscriptionsList,
} from "../../redux/actions/subscriptions";
import { cleanCancelSubscriptionState } from "../../redux/reducers/subscriptions/subscriptions";
import { RootState } from "../../redux/store";
import { SubscriptionsPlanDataT } from "../../types/subscriptionsTypes";

import Confirm from "../../Components/Confirm/Confirm";
import LoaderAbsolute from "../../Components/LoaderAbsolute/LoaderAbsolute";
import styles from "./styles.module.scss";

const ActiveSubscriptions = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(0);

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const bearer = searchParams.get("bearer") || "";
  const per_page = searchParams.get("per_page") || "";
  const page = searchParams.get("page") || "";

  const { allSubscriptionsList, cancelSubscriptionsState } = useAppSelector(
    (state: RootState) => state.subscriptions
  );

  useEffect(() => {
    if (openDelete) {
      setOpenDelete(false);
      dispatch(cleanCancelSubscriptionState());
    }
  }, [cancelSubscriptionsState.success]);

  useEffect(() => {
    dispatch(getAllSubscriptionsList({ per_page, page: `${+page + 1}` }));
  }, [per_page, page]);

  const confirmDelete = (id: number) => {
    setOpenDelete(true);
    setSelectedItemId(id);
  };

  const onCancelSubscription = () => {
    if (selectedItemId || selectedItemId === 0) {
      dispatch(cancelSubscription({ id: selectedItemId }));
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    //@ts-ignore
    setSearchParams({
      bearer,
      per_page,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //@ts-ignore
    setSearchParams({
      bearer,
      per_page: event.target.value,
      page: 0,
    });
  };

  return (
    <>
      {allSubscriptionsList?.isLoading && <LoaderAbsolute />}
      <Grid className={styles.wrapper} container flexDirection="column">
        <Grid item className={styles.container}>
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="h3">Active plans</Typography>
            </Grid>
          </Grid>
          <Grid mt={4} container flexDirection="row" justifyContent="center">
            <TableContainer component={Paper}>
              {!allSubscriptionsList?.data?.length &&
              allSubscriptionsList.success &&
              !allSubscriptionsList?.isLoading ? (
                <Grid
                  container
                  justifyContent="center"
                  sx={{ height: 350 }}
                  alignItems="center"
                >
                  <Typography variant="h2">
                    Have no active subscription yes
                  </Typography>
                </Grid>
              ) : (
                <Table aria-label="simple table" className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Client email</TableCell>

                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Plan</TableCell>
                      <TableCell align="left">Start date</TableCell>
                      <TableCell align="left">End date</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allSubscriptionsList?.data?.map(
                      (row: SubscriptionsPlanDataT) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.clientEmail}
                          </TableCell>
                          <TableCell align="left">
                            {row.statusDisplay}
                          </TableCell>
                          <TableCell align="left">{row.plan}</TableCell>
                          <TableCell align="left">{row.startDate}</TableCell>
                          <TableCell align="left">{row.endDate}</TableCell>
                          <TableCell align="left">
                            {row.status === "active" ||
                            row.status === "not_started" ? (
                              <Button
                                onClick={() => confirmDelete(row.id)}
                                color="error"
                              >
                                Cancel
                              </Button>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            {!allSubscriptionsList?.data?.length &&
            allSubscriptionsList?.isLoading ? null : (
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={
                  allSubscriptionsList?.meta?.total
                    ? allSubscriptionsList?.meta?.total
                    : 0
                }
                rowsPerPage={per_page ? +per_page : 10}
                page={page ? +page : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Grid>
        </Grid>
        <Confirm
          text={"Would you like to cancel the subscription?"}
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          action={onCancelSubscription}
          color={"danger"}
          confirmButtonText={"Yes"}
        />
      </Grid>
    </>
  );
};

export default ActiveSubscriptions;
