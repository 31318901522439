import React, { useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import OkIconBid from "../../assets/images/Icon_ok_big.svg";

import { useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/store";
import styles from "./styles.module.scss";

function PaymentSuccess() {
  const [searchParams, setSearchParams] = useSearchParams();
  const invoiceId = searchParams.get("invoiceId") || "";
  const transactionId = searchParams.get("transactionId") || "";
  const webhookSuccessRequested =
    searchParams.get("webhookSuccessRequested") || "";
  const payment_intent = searchParams.get("payment_intent") || "";
  const payment_intent_client_secret =
    searchParams.get("payment_intent_client_secret") || "";
  const redirect_status = searchParams.get("redirect_status") || "";

  const { webhookSuccessRequestState } = useAppSelector(
    (state: RootState) => state.payments
  );

  useEffect(() => {
    if (webhookSuccessRequestState?.success) {
      setSearchParams({
        invoiceId,
        transactionId,
        webhookSuccessRequested: "true",
        payment_intent,
        payment_intent_client_secret,
        redirect_status,
      });
    }
  }, [webhookSuccessRequestState?.success]);

  return (
    <Grid
      className={styles.container}
      container
      justifyContent="center"
      flexDirection="column"
    >
      <div className={styles.topBackground} />
      <img src={OkIconBid} />
      <Typography variant="h1">Payment successful</Typography>
      <Typography variant="h3">Thanks for using CleverFleet!</Typography>
      <div className={styles.bottomBackground} />
    </Grid>
  );
}

export default PaymentSuccess;
