import React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import useOptionsPayment from "../../hooks/useOptionsPayment";

import history from "../../utils/history";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setAlert } from "../../redux/reducers/alert/alert";
import { addSubscriptions } from "../../redux/actions/subscriptions";
import { RootState } from "../../redux/store";
import { commonConfig } from "../../config";

import Button from "../../Components/Button/Button";

import BankLogo from "../../assets/images/Icon_bank.svg";
import styles from "./styles.module.scss";

const CheckoutForm: React.FC<{
  paymentRequest?: any;
  promoCode: string;
  setPromoCode: (v: string) => void;
  promoCodeValue?: string;
  setPromoCodeValue: (v?: string) => void;
}> = ({
  paymentRequest,
  promoCode,
  setPromoCode,
  promoCodeValue,
  setPromoCodeValue,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const bearer = searchParams.get("bearer") || "";
  const amount = searchParams.get("amount") || "";
  const companyName = searchParams.get("companyName") || "";
  const userCurrency = searchParams.get("userCurrency") || "";
  const invoiceId = searchParams.get("invoiceId") || "";
  const invoiceCurrency = searchParams.get("invoiceCurrency") || "";
  const testMode = searchParams.get("testMode") || "";
  const name = searchParams.get("name") || "";
  const email = searchParams.get("email") || "";
  const planId = searchParams.get("planId") || "";
  const startDate = searchParams.get("startDate") || "";
  const durationInMonth = searchParams.get("durationInMonth") || "";
  const contractorId = searchParams.get("contractorId") || "";
  const contractId = searchParams.get("contractId") || "";
  const prepaymentAmount = searchParams.get("prepaymentAmount") || "";

  const { paymentProcessState } = useAppSelector(
    (state: RootState) => state.payments
  );

  const optionsPayment: any = useOptionsPayment(paymentRequest);

  const onShowError = (error: string) => {
    dispatch(
      setAlert({
        type: "error",
        message: error,
      })
    );
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    if (planId && startDate && durationInMonth && contractId && contractorId) {
      const card = elements?.getElement(CardElement)!;
      const stripeData = await stripe?.createPaymentMethod({
        type: "card",
        card: card,
        billing_details: {},
      });
      if (stripeData) {
        dispatch(
          addSubscriptions({
            paymentMethodId: stripeData?.paymentMethod?.id,
            planId,
            startDate,
            durationInMonth: +durationInMonth,
            contractId,
            contractorId,
            promoCode: promoCodeValue,
            prepaymentAmount: prepaymentAmount,
          })
        );
      }
      if (stripeData.error?.message) {
        onShowError(stripeData.error?.message);
      }
    } else {
      stripe
        .confirmPayment({
          elements,
          confirmParams: {
            return_url: `${commonConfig.serverHost}status?invoiceId=${paymentProcessState.data?.invoiceId}&transactionId=${paymentProcessState.data?.transactionId}&testMode=${testMode}`,
          },
        })
        .then((res) => {
          console.log("res", res);
          if (res.error?.message) {
            onShowError(res.error.message);
          }
        });
    }
  };

  const onBackButtonClick = () => {
    if (planId && startDate) {
      history.back();
    } else {
      history.push(
        `/payment_invoice?bearer=${bearer}&amount=${amount}&invoiceId=${invoiceId}&companyName=${companyName}&testMode=${testMode}&userCurrency=${userCurrency}&invoiceCurrency=${invoiceCurrency}&name=${name}&email=${email}`
      );
    }
  };

  return (
    <Grid container justifyContent={"center"} alignItems="center" p={4}>
      <Grid item xs={12}>
        <form>
          <Grid
            container
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            className={styles.cardContainer}
          >
            <Grid
              item
              className={styles.cardDataHeader}
              pr={4}
              pl={4}
              pt={2}
              pb={2}
            >
              <img src={BankLogo} />
              <Typography variant="h2">Payment details</Typography>
            </Grid>
            <Grid item className={styles.cardDataWrapper} xs={6} mb={1} p={4}>
              {planId && startDate ? <CardElement /> : <PaymentElement />}
            </Grid>
            {/* {planId &&
            startDate &&
            durationInMonth &&
            contractId &&
            contractorId ? (
              <Grid item mt={4} mb={2} className={styles.promoCodeWrapper}>
                <PromoCodeInput
                  value={promoCode}
                  onValueChange={setPromoCode}
                  onSetValue={setPromoCodeValue}
                />
              </Grid>
            ) : null} */}
            <Grid
              xs={6}
              item
              alignItems="center"
              className={styles.buttonWrapper}
            >
              <Grid container justifyContent={"space-between"}>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    onClick={onBackButtonClick}
                    text={"Back"}
                  />
                </Grid>
                {/* {paymentRequest && planId ? (
                  <Grid item xs={3}>
                    <PaymentRequestButtonElement options={optionsPayment} />
                  </Grid>
                ) : null} */}
                <Grid item xs={3}>
                  <Button
                    disabled={!stripe}
                    onClick={handleSubmit}
                    text={"Submit"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default CheckoutForm;
