import React, { useMemo } from "react";
import { Grid, Box, Typography } from "@mui/material";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import CardLogo from "../../assets/images/MastedCardLogo.svg";
import styles from "./styles.module.scss";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "14px",
          color: "#fff",
          fontFamily: "Montserrat-regular, Open Sans, Helvetica Neue",
          fontWeight: "400",
          "::placeholder": {
            // color: "transparent",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
      classes: {
        base: styles.CardNumberElementBase,
      },
    }),
    []
  );

  return options;
};

type CardComponentT = {
  setCardExpiryComplete: (v: boolean) => void;
  setCardCvcComplete: (v: boolean) => void;
  setCardNumberComplete: (v: boolean) => void;
  setError: (e: string) => void;
};

const AddCardComponent: React.FC<CardComponentT> = ({
  setCardExpiryComplete,
  setCardCvcComplete,
  setCardNumberComplete,
  setError,
}) => {
  const options = useOptions();

  return (
    <Box className={styles.cardScreen}>
      <Grid
        container
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        className={styles.card}
      >
        <Grid item className={styles.cardRowImage}>
          <img className={styles.cardImage} src={CardLogo} alt="bank logo" />
        </Grid>
        <Grid item className={styles.cardRow}>
          <Typography variant="h4" className={styles.cardTitle}>
            Card number:
          </Typography>
          <CardNumberElement
            options={options}
            className={styles.stripeElement}
            onChange={(e: any) => {
              setError(e.error);
              setCardNumberComplete(e.complete);
            }}
          />
        </Grid>
        <Grid item className={styles.cardRow}>
          <Grid container flexDirection="row" justifyContent="space-between">
            <Grid item className={styles.cardRowItemLeft}>
              <CardExpiryElement
                options={options}
                className={styles.stripeElement}
                onChange={(e: any) => {
                  setError(e.error);
                  setCardExpiryComplete(e.complete);
                }}
              />
            </Grid>
            <Grid item className={styles.cardRowItemRight}>
              <CardCvcElement
                options={options}
                className={styles.stripeElement}
                onChange={(e: any) => {
                  setError(e.error);
                  setCardCvcComplete(e.complete);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCardComponent;
