import * as React from "react";
import { Box, Typography, Modal } from "@mui/material";

import Button from "../Button/Button";
import CloseIcon from "../../assets/images/close_big.svg";

import styles from "./styles.module.scss";

type DeletePaymentMethodModalT = {
  open: boolean;
  handleClose: () => void;
  title: string;
  onConfirm: () => void;
};

export default function DeletePaymentMethodModal({
  open,
  handleClose,
  title,
  onConfirm,
}: DeletePaymentMethodModalT) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.modal}>
        <Box className={styles.closeButtonLine}>
          <Box className={styles.closeButton} onClick={handleClose}>
            <img src={CloseIcon} />
          </Box>
        </Box>
        <Typography
          id="modal-modal-title"
          variant="h4"
          component="h4"
          className={styles.title}
        >
          {title}
        </Typography>
        <Box className={styles.buttonsWrapper}>
          <Button
            onClick={handleClose}
            text={"No"}
            variant={"outlined"}
          ></Button>
          <Button onClick={onConfirm} text={"Yes"} isRemove></Button>
        </Box>
      </Box>
    </Modal>
  );
}
