import { SubscriptionType } from "./subscriptions.types";

export const defaultState: SubscriptionType = {
  isLoading: false,
  plansList: [],
  buySubscriptionState: { isLoading: false },
  paymentIntentState: { isLoading: false },
  allSubscriptionsList: { isLoading: false },
  cancelSubscriptionsState: { isLoading: false },
  updateActivePlanState: { isLoading: false },
  promoCodeData: { isLoading: false },
};
