import React from "react";
import { CircularProgress, Grid } from "@mui/material";

function Loader() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100%", minHeight: 350 }}
      data-testid="loader"
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}

export default Loader;
