import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";

import { StyledEngineProvider } from "@mui/styled-engine-sc";

import Alert from "./Components/Alert/Alert";
import routes from "./routes";

import { setupStore } from "./redux/store";

function App() {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | CleverFleet" defaultTitle="CleverFleet" />
      <Provider store={setupStore()}>
        <Alert />
        <StyledEngineProvider injectFirst>{content}</StyledEngineProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
