import history from "../utils/history";

export const cancelGoBack = ({ urlToStay }: { urlToStay: string }) => {
  if (history.action === "POP") {
    window.location.reload();
    history.push(urlToStay);
  }
};

export const getEmailFromUrl = (locationSearch: string) => {
  const paramList = locationSearch?.split("&");
  const findEmailInList = paramList?.find((p) => p.includes("email"));
  return findEmailInList?.replace("email=", "") || "";
};

export function isInt(n: number) {
  return Number(n) === n && n % 1 === 0;
}

export function isFloat(n: number) {
  return Number(n) === n && n % 1 !== 0;
}
