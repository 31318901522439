import React, { useState, useEffect, useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import InvoicePaymentElement from "../../Components/InvoicePaymentElement/InvoicePaymentElement";
import LoaderAbsolute from "../../Components/LoaderAbsolute/LoaderAbsolute";
import PaymentMethodsSwitch from "../../Components/PaymentMethodsSwitch/PaymentMethodsSwitch";
import DeletePaymentMethodModal from "../../Components/DeletePaymentMethodModal/DeletePaymentMethodModal";

import { removePaymentMethod } from "../../redux/actions/payment";
import { stripeConfig } from "../../config";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/store";
import { getUserPaymentAccounts } from "../../redux/actions/user";
import { PaymentMethodsVariantsT } from "../../types/paymentsTypes";
import {
  payment_method_type_card,
  payment_method_type_bank,
} from "../../helpers/constants";

import BankLogo from "../../assets/images/Icon_bank.svg";
import styles from "./styles.module.scss";

//@ts-ignore
const stripePromise = loadStripe(stripeConfig.publicKey);

const PaymentInvoice = () => {
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSwitchValue, setSelectedSwitchValue] =
    useState<null | PaymentMethodsVariantsT>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedPaymentMethodToDelete, setSelectedPaymentMethodToDelete] =
    useState<null | { title: string; payment_method_id: string }>(null);
  const [activeCardIndex, setActiveCardIndex] = useState<null | number>(null);
  const [activeBankIndex, setActiveBankIndex] = useState<null | number>(null);

  const { paymentProcessState, addPaymentMethodState, removeCardState } =
    useAppSelector((state: RootState) => state.payments);
  const { paymentsAccounts } = useAppSelector((state: RootState) => state.user);

  const savedCard = paymentsAccounts?.data?.card || [];
  const savedBank = paymentsAccounts?.data?.bank_account || [];

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  useEffect(() => {
    dispatch(getUserPaymentAccounts());
  }, []);

  useEffect(() => {
    if (!paymentsAccounts?.data?.card?.length) {
      setActiveCardIndex(null);
    }
    if (!paymentsAccounts?.data?.bank_account?.length) {
      setActiveBankIndex(null);
    }
  }, [removeCardState]);

  useEffect(() => {
    if (paymentsAccounts?.isInitiated) {
      if (
        paymentsAccounts?.data?.bank_account?.length &&
        paymentsAccounts?.data?.bank_account[0].is_default
      ) {
        setBankSwitchValue();
      } else {
        setCardSwitchValue();
      }
    }
  }, [paymentsAccounts?.isInitiated]);

  const setCardSwitchValue = useCallback(
    () => setSelectedSwitchValue(payment_method_type_card),
    []
  );

  const setBankSwitchValue = useCallback(
    () => setSelectedSwitchValue(payment_method_type_bank),
    []
  );

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onAddNewCard = useCallback(() => {
    setActiveCardIndex(null);
    onCloseModal();
  }, []);

  const onAddNewBank = useCallback(() => {
    setActiveBankIndex(null);
    onCloseModal();
  }, []);

  const onOpenModal = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const onDeletePaymentMethod = () => {
    if (
      (selectedSwitchValue === payment_method_type_bank &&
        activeBankIndex !== null &&
        savedBank?.length) ||
      (selectedSwitchValue === payment_method_type_card &&
        activeCardIndex !== null &&
        savedCard?.length)
    ) {
      const activePaymentMethod =
        selectedSwitchValue === payment_method_type_bank
          ? //@ts-ignore
            savedBank[activeBankIndex]
          : selectedSwitchValue === payment_method_type_card
          ? //@ts-ignore
            savedCard[activeCardIndex]
          : null;

      if (activePaymentMethod) {
        setSelectedPaymentMethodToDelete({
          title:
            activePaymentMethod.type === payment_method_type_card
              ? "Are you sure you want to delete this card?"
              : "Are you sure you want to delete this bank account?",
          payment_method_id: activePaymentMethod.id,
        });
        handleOpenDeleteModal();
      }
    }
    onCloseModal();
  };

  const onConfirmDeletePaymentMethod = () => {
    if (selectedPaymentMethodToDelete) {
      dispatch(
        removePaymentMethod({
          payment_method_id: selectedPaymentMethodToDelete.payment_method_id,
        })
      );
    }
    handleCloseDeleteModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerEmptyBox} />
        <div className={styles.headerTitle}>
          <img src={BankLogo} />
          <h2>Payment details</h2>
        </div>
        {isModalOpen ? (
          <>
            <div className={styles.headerEmptyBox} />
            <div className={styles.menuContainer}>
              <div className={styles.menuCloseButton} onClick={onCloseModal}>
                <img src={require("../../assets/images/close.png")} />
              </div>
              <div
                className={styles.menuRow}
                onClick={
                  selectedSwitchValue === payment_method_type_card
                    ? onAddNewCard
                    : onAddNewBank
                }
              >
                Add
                {selectedSwitchValue === payment_method_type_card
                  ? " Card"
                  : " Bank"}
              </div>
              <div className={styles.separator} />
              <div className={styles.menuRow} onClick={onDeletePaymentMethod}>
                Delete
              </div>
            </div>
          </>
        ) : (selectedSwitchValue === payment_method_type_bank &&
            activeBankIndex !== null) ||
          (selectedSwitchValue === payment_method_type_card &&
            activeCardIndex !== null) ? (
          <div onClick={onOpenModal} className={styles.menuActive} />
        ) : (
          <div className={styles.headerEmptyBox} />
        )}
      </div>
      {selectedSwitchValue && (
        <PaymentMethodsSwitch
          setCardSwitchValue={setCardSwitchValue}
          setBankSwitchValue={setBankSwitchValue}
          selectedSwitchValue={selectedSwitchValue}
        />
      )}
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <InvoicePaymentElement
            selectedSwitchValue={selectedSwitchValue}
            setActiveCardIndex={setActiveCardIndex}
            setActiveBankIndex={setActiveBankIndex}
            activeCardIndex={activeCardIndex}
            activeBankIndex={activeBankIndex}
          />
        </Elements>
      )}
      {paymentProcessState?.isLoading ||
      addPaymentMethodState?.isLoading ||
      removeCardState?.isLoading ? (
        <LoaderAbsolute />
      ) : null}
      <DeletePaymentMethodModal
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        title={selectedPaymentMethodToDelete?.title || ""}
        onConfirm={onConfirmDeletePaymentMethod}
      />
    </div>
  );
};

export default PaymentInvoice;
