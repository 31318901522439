import axios from "../utils/axios";
import {
  GetActivePlanWithContractorT,
  SetDefaultPaymentMethodT,
} from "../types/userTypes";

export const user = {
  getUserPaymentAccounts: async () => {
    const { data } = await axios.get("/user/payment/accounts");
    return data;
  },
  getUserPaymentMethods: async () => {
    const { data } = await axios.get("/user/payment/methods");
    return data;
  },
  contractorSubscriptionDetails: async () => {
    const { data } = await axios.get("/user/payment/contractor-subscriptions");
    return data;
  },
  getActivePlanWithContractor: async (params: GetActivePlanWithContractorT) => {
    const { data } = await axios.get("/user/payment/subscriptions", { params });
    return data;
  },
  setDefaultPaymentMethod: async (body: SetDefaultPaymentMethodT) => {
    const { data } = await axios.post("/user/payment/default-method", body);
    return data;
  },
  getTaxes: async () => {
    const { data } = await axios.get("user/payment/taxes");
    return data;
  },
};
