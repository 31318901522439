import React, { memo } from "react";
import { Button as ButtonMUI } from "@mui/material";
import styles from "./styles.module.scss";

type PropsT = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
  disabled?: boolean;
  variant?: "text" | "contained" | "outlined";
  isRemove?: boolean;
};

const Button: React.FC<PropsT> = memo(
  ({ onClick, text, disabled, variant = "contained", isRemove }) => {
    return (
      <ButtonMUI
        disabled={disabled}
        className={`${styles.button} ${
          variant === "outlined" && styles.outlinedButton
        } ${disabled && styles.disable} ${isRemove && styles.remove}`}
        onClick={onClick}
      >
        {text}
      </ButtonMUI>
    );
  }
);

export default Button;
