import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import styles from "./styles.module.scss";

function PaymentFailed() {
  return (
    <Grid className={styles.container} container justifyContent="center">
      <Grid item>
        <Box className={styles.failedIconWrapper}>
          <ErrorIcon className={styles.failedIcon} color="error" />
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="h2">Payment Failed</Typography>
        <Typography variant="h4">Please try another payment method.</Typography>
      </Grid>
    </Grid>
  );
}

export default PaymentFailed;
