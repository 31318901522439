import React from "react";
import { CircularProgress, Grid } from "@mui/material";

import styles from "./styles.module.scss";

function LoaderAbsolute() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={styles.wrapper}
      data-testid="loader"
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}

export default LoaderAbsolute;
