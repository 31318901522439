import alertReducer from "./alert/alert";
import paymentsReducer from "./payments/payments";
import userReducer from "./user/user";
import subscriptionsReducer from "./subscriptions/subscriptions";

const reducers = {
  alert: alertReducer,
  payments: paymentsReducer,
  user: userReducer,
  subscriptions: subscriptionsReducer,
};

export default reducers;
