import React, { useMemo } from "react";

const useOptionsPayment = (paymentRequest: any) => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: "dark",
          height: "48px",
          type: "buy",
          width: "230px",
        },
      },
    }),
    [paymentRequest]
  );

  return options;
};

export default useOptionsPayment;
