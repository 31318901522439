import { createAsyncThunk } from "@reduxjs/toolkit";
import { subscriptions } from "../../api/subscriptions";
import {
  GetAllSubscriptionsListParamsT,
  CancelSubscriptionParamsT,
  UpdateActivePlanParamsT,
  GetPromoCodeDataParamsT,
  AddSubscriptionParamsT,
  UpdatePaymentMethodT,
  GetPlansParamsT,
} from "../../types/subscriptionsTypes";

export const getPlansList = createAsyncThunk(
  "subscriptions/userRegister",
  async (params: GetPlansParamsT, thunkAPI) => {
    try {
      return await subscriptions.getPlansList(params);
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addSubscriptions = createAsyncThunk(
  "subscriptions/addSubscriptions",
  async (params: AddSubscriptionParamsT, thunkAPI) => {
    try {
      return await subscriptions.addSubscriptions(params);
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscriptions/cancelSubscription",
  async (params: CancelSubscriptionParamsT, thunkAPI) => {
    try {
      const res = await subscriptions.cancelSubscription(params);
      return { ...res, id: params.id };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getPlanDetails = createAsyncThunk(
  "subscriptions/getPlanDetails",
  async (params: { id: string }, thunkAPI) => {
    try {
      return await subscriptions.getPlanDetails(params);
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const setupIntent = createAsyncThunk(
  "subscriptions/setupIntent",
  async (_: void, thunkAPI) => {
    try {
      return await subscriptions.setupIntent();
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getAllSubscriptionsList = createAsyncThunk(
  "subscriptions/getAllSubscriptionsList",
  async (params: GetAllSubscriptionsListParamsT, thunkAPI) => {
    try {
      return await subscriptions.getAllSubscriptionsList(params);
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateActivePlan = createAsyncThunk(
  "subscriptions/updateActivePlan",
  async (params: UpdateActivePlanParamsT, thunkAPI) => {
    try {
      return await subscriptions.updateActivePlan(params);
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getPromoCodeData = createAsyncThunk(
  "subscriptions/getPromoCodeData",
  async (params: GetPromoCodeDataParamsT, thunkAPI) => {
    try {
      const { data } = await subscriptions.getPromoCodeData(params);
      return data;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updatePaymentMethod = createAsyncThunk(
  "subscriptions/updatePaymentMethod",
  async (params: UpdatePaymentMethodT, thunkAPI) => {
    try {
      const { data } = await subscriptions.updatePaymentMethod(params);
      return data;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
