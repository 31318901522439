import React, { useState, useEffect } from "react";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Button from "../Button/Button";
import AddCardComponent from "../AddCardComponent/AddCardComponent";
import CardComponent from "../CardComponent/CardComponent";
import AddCardSuccess from "../AddCardSuccess/AddCardSuccess";

import { cleanAddPaymentMethodState } from "../../redux/reducers/payments/payments";
import { addPaymentMethod } from "../../redux/actions/payment";
import { setAlert } from "../../redux/reducers/alert/alert";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { payment_method_type_card } from "../../helpers/constants";
import { RootState } from "../../redux/store";
import {
  CardT,
  PaymentMethodsVariantsT,
  DeletePaymentMethodParamsT,
} from "../../types/paymentsTypes";

import styles from "./styles.module.scss";

const AddCard: React.FC<{
  cardData?: CardT;
  addPaymentMethodInMenu: (v: PaymentMethodsVariantsT) => void;
  deletePaymentMethodInMenu: (params: DeletePaymentMethodParamsT) => void;
}> = ({ cardData, addPaymentMethodInMenu, deletePaymentMethodInMenu }) => {
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardError, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { addPaymentMethodState } = useAppSelector(
    (state: RootState) => state.payments
  );

  const openSuccessModal = () => setIsSuccessModalOpen(true);
  const closeSuccessModal = () => {
    dispatch(cleanAddPaymentMethodState());
    setIsSuccessModalOpen(false);
  };

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  const onShowError = (error: string) => {
    dispatch(
      setAlert({
        type: "error",
        message: error,
      })
    );
  };

  useEffect(() => {
    return () => {
      onCloseModal();
    };
  }, [cardData]);

  useEffect(() => {
    if (
      addPaymentMethodState?.success &&
      addPaymentMethodState?.method === payment_method_type_card
    ) {
      openSuccessModal();
    }
  }, [addPaymentMethodState.success]);

  const onOpenModal = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    cardData && setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const card = elements?.getElement(CardNumberElement)!;

    const stripeData = await stripe?.createPaymentMethod({
      type: "card",
      card: card,
    });

    if (stripeData?.error?.message) {
      onShowError(stripeData.error.message);
      return;
    }

    stripeData.paymentMethod?.id &&
      dispatch(
        addPaymentMethod({
          payment_method_id: stripeData.paymentMethod?.id,
          method: payment_method_type_card,
        })
      );
  };

  let formComplete =
    cardNumberComplete &&
    cardCvcComplete &&
    cardExpiryComplete &&
    !cardError?.length;

  let submitButtonDisabled = !formComplete;

  const onCloseModals = () => {
    closeSuccessModal();
    onCloseModal();
  };

  return (
    <div className={styles.cardMain} onClick={onCloseModals}>
      <div className={styles.cardBodyHeader}>
        {isModalOpen ? (
          <div className={styles.menuContainer}>
            <div className={styles.menuCloseButton} onClick={onCloseModal}>
              <img src={require("../../assets/images/close.png")} />
            </div>
            <div
              className={styles.menuRow}
              onClick={() => addPaymentMethodInMenu(payment_method_type_card)}
            >
              Add
            </div>
            <div className={styles.separator} />
            <div
              className={styles.menuRow}
              onClick={() => {
                onCloseModal();
                deletePaymentMethodInMenu({
                  variant: payment_method_type_card,
                  payment_method_id: cardData?.id || "",
                });
              }}
            >
              Delete
            </div>
          </div>
        ) : (
          <div
            onClick={onOpenModal}
            className={cardData ? styles.menuActive : styles.menuHided}
          />
        )}
      </div>
      <div className={styles.cardBodyRow}>
        {cardData ? (
          <CardComponent
            cardData={cardData}
            isShowSelectDefaultMethodButton={true}
          />
        ) : (
          <AddCardComponent
            setCardNumberComplete={setCardNumberComplete}
            setCardCvcComplete={setCardCvcComplete}
            setCardExpiryComplete={setCardExpiryComplete}
            setError={setError}
          />
        )}
        {isSuccessModalOpen ? (
          <AddCardSuccess
            closeSuccessModal={closeSuccessModal}
            text={"Your card has been successfully linked"}
          />
        ) : null}
      </div>
      {!cardData && (
        <div className={styles.cardBodyFooter}>
          <Button
            text="Save Card"
            onClick={onSubmit}
            disabled={submitButtonDisabled}
          />
        </div>
      )}
    </div>
  );
};

export default AddCard;
