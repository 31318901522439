import React from "react";
import styles from "./styles.module.scss";
import IconBank from "../../assets/images/Icon_bank.svg";

const AddBankAccount: React.FC<{}> = () => {
  return (
    <div className={styles.addBankAccount}>
      <p>Add new bank account.</p>
      <img src={IconBank} />
    </div>
  );
};

export default AddBankAccount;
