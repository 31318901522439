import React, { useEffect } from "react";
import { Grid } from "@mui/material";

import history from "../../utils/history";
import { cancelGoBack } from "../../helpers/functionsHelpers";
import { commonConfig } from "../../../src/config";
import { cleanUpdateActivePlanState } from "../../redux/reducers/subscriptions/subscriptions";
import { useAppDispatch } from "../../hooks/redux";

import PaymentSuccess from "../../Components/PaymentSuccess/PaymentSuccess";
import UpdatingSuccess from "../../Components/UpdatingSuccess/UpdatingSuccess";

import styles from "./styles.module.scss";

const SuccessScreen: React.FC = () => {
  const dispatch = useAppDispatch();

  const params = new URLSearchParams(window.location.search);
  const isUpdatePlan = params.get("isUpdatePlan") || "";
  const contractId = params.get("contractId") || "";

  useEffect(() => {
    const urlToStay = `${commonConfig.bubbleHost}/contact_signing_page/${contractId}`;
    return () => {
      cancelGoBack({ urlToStay });
    };
  }, [history]);

  useEffect(() => {
    if (isUpdatePlan) {
      dispatch(cleanUpdateActivePlanState());
    }
  }, []);

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems="center"
      p={4}
      className={styles.wrapper}
    >
      <Grid item xs={12}>
        {isUpdatePlan ? <UpdatingSuccess /> : <PaymentSuccess />}
      </Grid>
    </Grid>
  );
};

export default SuccessScreen;
