import axios from "../utils/axios";
import {
  GetAllSubscriptionsListParamsT,
  CancelSubscriptionParamsT,
  UpdateActivePlanParamsT,
  GetPromoCodeDataParamsT,
  AddSubscriptionParamsT,
  UpdatePaymentMethodT,
  GetPlansParamsT,
} from "../types/subscriptionsTypes";

export const subscriptions = {
  getPlansList: async (params: GetPlansParamsT) => {
    const { data } = await axios.get("/subscription-plans", { params });
    return data.data;
  },
  addSubscriptions: async (body: AddSubscriptionParamsT) => {
    const { data } = await axios.post("/subscriptions", body);
    return data.data;
  },
  cancelSubscription: async (params: CancelSubscriptionParamsT) => {
    const { data } = await axios.delete(`/subscriptions/${params.id}`);
    return data;
  },
  getPlanDetails: async (params: { id: string }) => {
    const { data } = await axios.get(`/subscription-plans/${params.id}`);
    return data.data;
  },
  setupIntent: async () => {
    const { data } = await axios.get("/subscriptions");
    return data.data;
  },
  getAllSubscriptionsList: async (params: GetAllSubscriptionsListParamsT) => {
    const { data } = await axios.get(`/subscriptions/list`, { params });
    return data;
  },
  updateActivePlan: async (params: UpdateActivePlanParamsT) => {
    let body = {
      planId: params.planId,
      promoCode: params.promoCode,
    };
    const { data } = await axios.put(`/subscriptions/${params.id}`, body);
    return data;
  },
  getPromoCodeData: async (params: GetPromoCodeDataParamsT) => {
    const { data } = await axios.get(
      `/subscriptions/promo-codes/${params.promoCode}`
    );
    return data;
  },
  updatePaymentMethod: async (params: UpdatePaymentMethodT) => {
    let body = {
      paymentMethodId: params.paymentMethodId,
    };
    const { data } = await axios.put(
      `/subscriptions/${params.id}/payment-method`,
      body
    );
    return data;
  },
};
