import { createAsyncThunk } from "@reduxjs/toolkit";
import { user } from "../../api/user";
import {
  GetActivePlanWithContractorT,
  SetDefaultPaymentMethodT,
} from "../../types/userTypes";

export const getUserPaymentAccounts = createAsyncThunk(
  "user/getUserPaymentAccounts",
  async (_: void, thunkAPI) => {
    try {
      const { data } = await user.getUserPaymentAccounts();
      return {
        data,
      };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getUserPaymentMethods = createAsyncThunk(
  "user/getUserPaymentMethods",
  async (_: void, thunkAPI) => {
    try {
      const { data } = await user.getUserPaymentMethods();
      return {
        data,
      };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const contractorSubscriptionDetails = createAsyncThunk(
  "user/contractorSubscriptionDetails",
  async (_: void, thunkAPI) => {
    try {
      const { data } = await user.contractorSubscriptionDetails();
      return {
        data,
      };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const getActivePlanWithContractor = createAsyncThunk(
  "user/getActivePlanWithContractor",
  async (params: GetActivePlanWithContractorT, thunkAPI) => {
    try {
      const { data } = await user.getActivePlanWithContractor(params);
      return {
        data,
      };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const setDefaultPaymentMethod = createAsyncThunk(
  "user/setDefaultPaymentMethod",
  async (params: SetDefaultPaymentMethodT, thunkAPI) => {
    try {
      const res = await user.setDefaultPaymentMethod(params);
      return {
        id: params.payment_method_id,
        message: res.message,
      };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getTaxes = createAsyncThunk(
  "payment/getTaxes",
  async (_: void, thunkAPI) => {
    try {
      const { data } = await user.getTaxes();
      return data;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
