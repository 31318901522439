import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";

import ArrowButton from "../ArrowButton/ArrowButton";
import AddBank from "./AddBank";

import { DeletePaymentMethodParamsT, BankT } from "../../types/paymentsTypes";

import styles from "./styles.module.scss";

type BanksComponentT = {
  savedBank: Array<BankT>;
  onDeletePaymentMethodMenuClick: (p: DeletePaymentMethodParamsT) => void;
  stripePromise: any;
};

const BanksComponent: React.FC<BanksComponentT> = ({
  savedBank,
  onDeletePaymentMethodMenuClick,
  stripePromise,
}) => {
  const isShowBankSlider = savedBank?.length > 0;

  const [activeBankIndex, setActiveBankIndex] = useState<null | number>(null);

  useEffect(() => {
    if (savedBank?.length) {
      setActiveBankIndex(0);
    }
  }, [savedBank]);

  const onSliderClickLeftBank = () => {
    if (activeBankIndex !== 0 && activeBankIndex !== null) {
      //@ts-ignore
      setActiveBankIndex((value) => +value - 1);
    } else if (activeBankIndex === null) {
      //@ts-ignore
      setActiveBankIndex((value) => savedBank?.length - 1);
    }
  };

  const onSliderClickRightBank = () => {
    if (activeBankIndex === savedBank?.length - 1) {
      setActiveBankIndex(null);
    } else if (activeBankIndex !== null) {
      //@ts-ignore
      setActiveBankIndex((value) => value + 1);
    }
  };

  const onAddPaymentMethodMenuClick = () => {
    setActiveBankIndex(null);
  };

  return (
    <div className={styles.cardBody}>
      {isShowBankSlider && (
        <div className={styles.arrowLeft}>
          <ArrowButton
            variant="left"
            onClick={onSliderClickLeftBank}
            isDisabled={activeBankIndex === 0}
          />
        </div>
      )}
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <AddBank
            // @ts-ignore
            bankData={
              activeBankIndex === null ? undefined : savedBank[activeBankIndex]
            }
            isHideNoBankTitle={!!savedBank?.length && activeBankIndex === null}
            addPaymentMethodInMenu={onAddPaymentMethodMenuClick}
            deletePaymentMethodInMenu={onDeletePaymentMethodMenuClick}
          />
        </Elements>
      )}
      {isShowBankSlider && (
        <div className={styles.arrowRight}>
          <ArrowButton
            variant="right"
            onClick={onSliderClickRightBank}
            isDisabled={
              activeBankIndex === savedBank?.length || activeBankIndex === null
            }
          />
        </div>
      )}
    </div>
  );
};

export default BanksComponent;
