import { createAsyncThunk } from "@reduxjs/toolkit";
import { payment } from "../../api/payment";
import {
  PaymentProcessBodyT,
  AddPaymentMethodT,
  RemoveMethodParamsT,
  WebhookSuccessRequestParams,
} from "../../types/paymentsTypes";

export const getTestPublicToken = createAsyncThunk(
  "payment/getTestPublicToken",
  async (_: void, thunkAPI) => {
    try {
      return await payment.getTestPublicToken();
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
export const getBalance = createAsyncThunk(
  "payment/getBalance",
  async (_: void, thunkAPI) => {
    try {
      return await payment.getBalance();
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
export const getMethods = createAsyncThunk(
  "payment/getMethods",
  async (_: void, thunkAPI) => {
    try {
      const { data } = await payment.getMethods();
      return {
        data,
      };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const paymentProcess = createAsyncThunk(
  "payment/paymentProcess",
  async (body: PaymentProcessBodyT, thunkAPI) => {
    try {
      const { message, data } = await payment.paymentProcess(body);
      return {
        message,
        data,
        isRedirect: !!body.paymentMethodId,
        paymentMethodId: body?.paymentMethodId,
      };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addPaymentMethod = createAsyncThunk(
  "payment/addPaymentMethod",
  async (body: AddPaymentMethodT, thunkAPI) => {
    try {
      const { message, data } = await payment.addPaymentMethod({
        payment_method_id: body.payment_method_id,
        public_token: body.public_token,
      });
      return {
        message,
        data,
        method: body.method,
        type: body.type,
      };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const removePaymentMethod = createAsyncThunk(
  "payment/removePaymentMethod",
  async (params: RemoveMethodParamsT, thunkAPI) => {
    try {
      const { data, message } = await payment.removePaymentMethod({
        payment_method_id: params.payment_method_id,
      });
      return {
        data,
        message,
        payment_method_id: params.payment_method_id,
        publicToken: params.publicToken,
      };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const webhookSuccessRequest = createAsyncThunk(
  "payment/webhookSuccessRequest",
  async (params: WebhookSuccessRequestParams, thunkAPI) => {
    try {
      const { data } = await payment.webhookSuccessRequest(params);
      return {
        data,
      };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
