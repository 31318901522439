import React from "react";
import { Grid, Box, Typography } from "@mui/material";

import DefaultMethodRadio from "../DefaultMethodRadioComponent/DefaultMethodRadioComponent";
import CardLogo from "../../assets/images/MastedCardLogo.svg";

import { useAppDispatch } from "../../hooks/redux";
import { CardT } from "../../types/paymentsTypes";
import { setDefaultPaymentMethod } from "../../redux/actions/user";
import styles from "./styles.module.scss";

type CardComponentT = {
  cardData: CardT;
  isShowSelectDefaultMethodButton?: boolean;
};

const CardComponent: React.FC<CardComponentT> = ({
  cardData,
  isShowSelectDefaultMethodButton,
}) => {
  const dispatch = useAppDispatch();

  const onSetDefaultMethod = () => {
    if (!cardData.is_default) {
      dispatch(setDefaultPaymentMethod({ payment_method_id: cardData.id }));
    }
  };

  return (
    <Box className={styles.cardScreen}>
      <Grid
        container
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        className={styles.card}
      >
        <Grid item className={styles.cardRowImage}>
          {cardData?.card?.brand === "visa" ? (
            <img
              className={styles.visaCardImage}
              src={require("../../assets/images/VisaCardLogo.png")}
              alt="bank logo"
            />
          ) : (
            <img
              className={styles.masterCardImage}
              src={CardLogo}
              alt="bank logo"
            />
          )}
        </Grid>
        <Grid item className={styles.cardRow}>
          <Typography variant="h4" className={styles.cardTitle}>
            Card number:
          </Typography>
          <p className={styles.fieldValue}>
            **** **** **** {cardData.card.last4}
          </p>
        </Grid>
        <Grid item className={styles.cardRow}>
          <Grid container flexDirection="row" justifyContent="space-between">
            <Grid item className={styles.cardRowItemLeft}>
              <p className={styles.fieldValue}>
                {cardData.card?.exp_month}/{cardData.card?.exp_year}
              </p>
            </Grid>
            <Grid item className={styles.cardRowItemRight}></Grid>
          </Grid>
        </Grid>
      </Grid>
      {isShowSelectDefaultMethodButton && (
        <DefaultMethodRadio
          isDefault={cardData.is_default}
          onDefaultMethodChange={onSetDefaultMethod}
        />
      )}
    </Box>
  );
};

export default CardComponent;
