import { userType } from "./user.types";

export const defaultState: userType = {
  isLoading: false,
  paymentsAccounts: {
    data: {
      card: [],
      bank_account: [],
    },
  },
  paymentsMethods: {
    data: [],
  },
  taxesData: {
    taxesList: [],
    isLoading: false,
    isInitiated: false,
  },
};
