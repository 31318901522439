import React, { useEffect, useState, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/store";
import {
  getPlanDetails,
  addSubscriptions,
} from "../../redux/actions/subscriptions";
import { getUserPaymentAccounts } from "../../redux/actions/user";
import { removePaymentMethod } from "../../redux/actions/payment";
import { setAlert } from "../../redux/reducers/alert/alert";
import { PaymentMethodsVariantsT } from "../../types/paymentsTypes";
import history from "../../utils/history";
import {
  payment_method_type_card,
  payment_method_type_bank,
} from "../../helpers/constants";
import { stripeConfig } from "../../config";
import { cleanAddPaymentMethodState } from "../../redux/reducers/payments/payments";

import SubscriptionsPaymentElement from "../../Components/SubscriptionsPaymentElement/SubscriptionsPaymentElement";
import Loader from "../../Components/Loader/Loader";
import LoaderAbsolute from "../../Components/LoaderAbsolute/LoaderAbsolute";
import SubscriptionPlanDetailsData from "../../Components/SubscriptionPlanDetailsData/SubscriptionPlanDetailsData";
import PaymentMethodsSwitch from "../../Components/PaymentMethodsSwitch/PaymentMethodsSwitch";
import DeletePaymentMethodModal from "../../Components/DeletePaymentMethodModal/DeletePaymentMethodModal";
import AddCardSuccess from "../../Components/AddCardSuccess/AddCardSuccess";

import ButtonBack from "../../assets/images/Button_back.svg";
import BankLogo from "../../assets/images/Icon_bank.svg";
import styles from "./styles.module.scss";
//@ts-ignore
const stripePromise = loadStripe(stripeConfig.publicKey);

const SubscriptionDetails = () => {
  const { id = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const testMode = searchParams.get("testMode");
  const startDate = searchParams.get("startDate") || "";
  const durationInMonth = searchParams.get("durationInMonth") || "";
  const contractorId = searchParams.get("contractorId") || "";
  const contractId = searchParams.get("contractId") || "";
  const prepaymentAmount = searchParams.get("prepaymentAmount") || "";
  const contractorName = searchParams.get("contractorName") || "";
  const contractName = searchParams.get("contractName") || "";
  const contractorCharged = searchParams.get("contractorCharged") || "";

  const prePeyData =
    contractName && contractorName && contractorCharged && prepaymentAmount
      ? {
          contractName,
          contractorName,
          contractorCharged,
          prepaymentAmount: +prepaymentAmount,
        }
      : null;

  const dispatch = useAppDispatch();

  const { planDetails, buySubscriptionState } = useAppSelector(
    (state: RootState) => state.subscriptions
  );
  const { addPaymentMethodState, removeCardState } = useAppSelector(
    (state: RootState) => state.payments
  );
  const { paymentsAccounts } = useAppSelector((state: RootState) => state.user);

  const [selectedSwitchValue, setSelectedSwitchValue] =
    useState<null | PaymentMethodsVariantsT>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState<null | number>(null);
  const [activeBankIndex, setActiveBankIndex] = useState<null | number>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedPaymentMethodToDelete, setSelectedPaymentMethodToDelete] =
    useState<null | { title: string; payment_method_id: string }>(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const savedCard = paymentsAccounts?.data?.card || [];
  const savedBank = paymentsAccounts?.data?.bank_account || [];

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const openSuccessModal = () => setIsSuccessModalOpen(true);
  const closeSuccessModal = () => {
    dispatch(cleanAddPaymentMethodState());
    setIsSuccessModalOpen(false);
  };

  useEffect(() => {
    if (addPaymentMethodState?.success) {
      openSuccessModal();
    }
  }, [addPaymentMethodState.success]);

  useEffect(() => {
    dispatch(getPlanDetails({ id }));
    dispatch(getUserPaymentAccounts());
    if (!durationInMonth || !startDate || !contractId || !contractorId) {
      dispatch(
        setAlert({
          type: "error",
          message: "Missing all required parameters",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (buySubscriptionState?.success) {
      history.push(
        `/success_screen?contractId=${contractId}&testMode=${testMode}`
      );
    }
  }, [buySubscriptionState?.success]);

  useEffect(() => {
    if (paymentsAccounts?.isInitiated) {
      if (
        paymentsAccounts?.data?.bank_account?.length &&
        paymentsAccounts?.data?.bank_account[0].is_default
      ) {
        setBankSwitchValue();
      } else {
        setCardSwitchValue();
      }
    }
  }, [paymentsAccounts?.isInitiated]);

  const onConfirmDeletePaymentMethod = () => {
    if (selectedPaymentMethodToDelete) {
      dispatch(
        removePaymentMethod({
          payment_method_id: selectedPaymentMethodToDelete.payment_method_id,
        })
      );
    }
    handleCloseDeleteModal();
  };

  const onSubmitPayment = ({
    paymentMethodId,
    promoCodeValue,
  }: {
    paymentMethodId: string;
    promoCodeValue?: string;
  }) => {
    dispatch(
      addSubscriptions({
        paymentMethodId: paymentMethodId,
        planId: id,
        startDate,
        durationInMonth: +durationInMonth,
        contractorId,
        contractId,
        promoCode: promoCodeValue?.length ? promoCodeValue : undefined,
        prepaymentAmount: prepaymentAmount,
      })
    );
  };

  const setCardSwitchValue = useCallback(() => {
    onCloseModal();
    setSelectedSwitchValue(payment_method_type_card);
  }, []);

  const setBankSwitchValue = useCallback(() => {
    onCloseModal();
    setSelectedSwitchValue(payment_method_type_bank);
  }, []);

  const onOpenModal = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onAddNewCard = useCallback(() => {
    setActiveCardIndex(null);
    onCloseModal();
  }, []);

  const onAddNewBank = useCallback(() => {
    setActiveBankIndex(null);
    onCloseModal();
  }, []);

  const onDeletePaymentMethod = () => {
    if (
      (selectedSwitchValue === payment_method_type_bank &&
        activeBankIndex !== null &&
        savedBank?.length) ||
      (selectedSwitchValue === payment_method_type_card &&
        activeCardIndex !== null &&
        savedCard?.length)
    ) {
      const activePaymentMethod =
        selectedSwitchValue === payment_method_type_bank
          ? //@ts-ignore
            savedBank[activeBankIndex]
          : selectedSwitchValue === payment_method_type_card
          ? //@ts-ignore
            savedCard[activeCardIndex]
          : null;
      if (activePaymentMethod) {
        setSelectedPaymentMethodToDelete({
          title:
            activePaymentMethod.type === payment_method_type_card
              ? "Are you sure you want to delete this card?"
              : "Are you sure you want to delete this bank account?",
          payment_method_id: activePaymentMethod.id,
        });
        handleOpenDeleteModal();
      }
    }
    onCloseModal();
  };

  const onBackClick = () => {
    history.back();
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Button className={styles.backButton} onClick={onBackClick}>
          <img src={ButtonBack} />
        </Button>
        <h1>Checkout</h1>
        <h2>Choose the best way to work with your Ukrainian talent.</h2>
      </div>
      <div className={styles.content}>
        <div className={styles.planDetailsWrapper}>
          {planDetails ? (
            <SubscriptionPlanDetailsData
              data={planDetails}
              prePeyData={prePeyData}
            />
          ) : (
            <Loader />
          )}
        </div>
        <div className={styles.paymentDetailsWrapper}>
          <div className={styles.header}>
            <div className={styles.headerEmptyBox} />
            <div className={styles.headerTitle}>
              <img src={BankLogo} />
              <h2>Payment details</h2>
            </div>
            {isModalOpen ? (
              <>
                <div className={styles.headerEmptyBox} />
                <div className={styles.menuContainer}>
                  <div
                    className={styles.menuCloseButton}
                    onClick={onCloseModal}
                  >
                    <img src={require("../../assets/images/close.png")} />
                  </div>
                  <div
                    className={styles.menuRow}
                    onClick={
                      selectedSwitchValue === payment_method_type_card
                        ? onAddNewCard
                        : onAddNewBank
                    }
                  >
                    Add
                    {selectedSwitchValue === payment_method_type_card
                      ? " Card"
                      : " Bank"}
                  </div>
                  <div className={styles.separator} />
                  <div
                    className={styles.menuRow}
                    onClick={onDeletePaymentMethod}
                  >
                    Delete
                  </div>
                </div>
              </>
            ) : (selectedSwitchValue === payment_method_type_bank &&
                activeBankIndex !== null) ||
              (selectedSwitchValue === payment_method_type_card &&
                activeCardIndex !== null) ? (
              <div onClick={onOpenModal} className={styles.menuActive} />
            ) : (
              <div className={styles.headerEmptyBox} />
            )}
          </div>
          {selectedSwitchValue && (
            <PaymentMethodsSwitch
              setCardSwitchValue={setCardSwitchValue}
              setBankSwitchValue={setBankSwitchValue}
              selectedSwitchValue={selectedSwitchValue}
            />
          )}
          {stripePromise && (
            <Elements stripe={stripePromise}>
              <SubscriptionsPaymentElement
                selectedSwitchValue={selectedSwitchValue}
                onSubmitPayment={onSubmitPayment}
                setActiveCardIndex={setActiveCardIndex}
                setActiveBankIndex={setActiveBankIndex}
                activeCardIndex={activeCardIndex}
                activeBankIndex={activeBankIndex}
              />
            </Elements>
          )}
          {isSuccessModalOpen ? (
            <AddCardSuccess
              closeSuccessModal={closeSuccessModal}
              text={
                addPaymentMethodState?.method === payment_method_type_card
                  ? "Your card has been successfully linked"
                  : "Your bank account has been added"
              }
            />
          ) : null}
        </div>
        <DeletePaymentMethodModal
          open={openDeleteModal}
          handleClose={handleCloseDeleteModal}
          title={selectedPaymentMethodToDelete?.title || ""}
          onConfirm={onConfirmDeletePaymentMethod}
        />
      </div>
      {buySubscriptionState?.isLoading ||
      addPaymentMethodState?.isLoading ||
      removeCardState?.isLoading ? (
        <LoaderAbsolute />
      ) : null}
    </div>
  );
};

export default SubscriptionDetails;
