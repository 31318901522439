import axios from "../utils/axios";
import {
  PaymentProcessBodyT,
  AddPaymentMethodT,
  RemoveMethodParamsT,
  WebhookSuccessRequestParams,
} from "../types/paymentsTypes";
import { stripeConfig } from "../config";

export const payment = {
  getTestPublicToken: async () => {
    const { data } = await axios.get("/plaid/test/public-token");
    return data;
  },
  getBalance: async () => {
    const { data } = await axios.get("/plaid/balance");
    return data;
  },
  getMethods: async () => {
    const { data } = await axios.get("/payment/methods");
    return data;
  },
  paymentProcess: async (body: PaymentProcessBodyT) => {
    const { data } = await axios.post("/payment/process", body);
    return data;
  },
  addPaymentMethod: async (body: AddPaymentMethodT) => {
    const { data } = await axios.post("/payment/methods", body);
    return data;
  },
  removePaymentMethod: async (params: RemoveMethodParamsT) => {
    const { data } = await axios.delete("/payment/methods", { params });
    return data;
  },
  webhookSuccessRequest: async (body: WebhookSuccessRequestParams) => {
    const { data } = await axios.post("/payment_result_webhook", body, {
      baseURL: stripeConfig.webhookApi,
    });
    return data;
  },
};
