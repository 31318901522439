import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";

import styles from "./styles.module.scss";

function UpdatingSuccess() {
  return (
    <Grid className={styles.container} container justifyContent="center">
      <Grid item>
        <Box className={styles.successIconWrapper}>
          <CheckCircleSharpIcon
            className={styles.successIcon}
            color="success"
          />
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="h2">
          Subscription plan was updates successful
        </Typography>
        <Typography variant="h4">Thank you</Typography>
      </Grid>
    </Grid>
  );
}

export default UpdatingSuccess;
