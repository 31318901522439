import { PaymentsType } from "./payments.types";

export const defaultState: PaymentsType = {
  isLoading: false,
  paymentMethods: [],
  linkTokenState: {
    isLoading: false,
  },
  paymentProcessState: {
    isLoading: false,
  },
  addPaymentMethodState: {
    isLoading: false,
  },
  removeCardState: {
    isLoading: false,
  },
};
