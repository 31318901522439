import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";

import ArrowButton from "../ArrowButton/ArrowButton";
import AddCard from "./AddCard";

import { DeletePaymentMethodParamsT, CardT } from "../../types/paymentsTypes";

import styles from "./styles.module.scss";

type CardComponentT = {
  savedCard: Array<CardT>;
  stripePromise: any;
  onDeletePaymentMethodMenuClick: (p: DeletePaymentMethodParamsT) => void;
};

const CardsComponent: React.FC<CardComponentT> = ({
  savedCard,
  stripePromise,
  onDeletePaymentMethodMenuClick,
}) => {
  const isShowCardSlider = savedCard?.length > 0;

  useEffect(() => {
    if (savedCard?.length) {
      setActiveCardIndex(0);
    }
  }, [savedCard]);

  const [activeCardIndex, setActiveCardIndex] = useState<null | number>(null);

  const onSliderClickLeftCard = () => {
    if (activeCardIndex !== 0 && activeCardIndex !== null) {
      //@ts-ignore
      setActiveCardIndex((value) => +value - 1);
    } else if (activeCardIndex === null) {
      //@ts-ignore
      setActiveCardIndex((value) => savedCard?.length - 1);
    }
  };

  const onSliderClickRightCard = () => {
    if (activeCardIndex === savedCard?.length - 1) {
      setActiveCardIndex(null);
    } else if (activeCardIndex !== null) {
      //@ts-ignore
      setActiveCardIndex((value) => value + 1);
    }
  };

  const onAddPaymentMethodMenuClick = () => {
    setActiveCardIndex(null);
  };

  return (
    <div className={styles.cardBody}>
      {isShowCardSlider && (
        <div className={styles.arrowLeft}>
          <ArrowButton
            variant="left"
            onClick={onSliderClickLeftCard}
            isDisabled={activeCardIndex === 0}
          />
        </div>
      )}
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <AddCard
            //@ts-ignore
            cardData={
              activeCardIndex === null ? undefined : savedCard[activeCardIndex]
            }
            addPaymentMethodInMenu={onAddPaymentMethodMenuClick}
            deletePaymentMethodInMenu={onDeletePaymentMethodMenuClick}
          />
        </Elements>
      )}
      {isShowCardSlider && (
        <div className={styles.arrowRight}>
          <ArrowButton
            variant="right"
            onClick={onSliderClickRightCard}
            isDisabled={
              activeCardIndex === savedCard?.length || activeCardIndex === null
            }
          />
        </div>
      )}
    </div>
  );
};

export default CardsComponent;
