import React, { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";

import usePaymentRequest from "../../hooks/usePaymentRequest";
import { useAppDispatch } from "../../hooks/redux";
import { addSubscriptions } from "../../redux/actions/subscriptions";
import { setAlert } from "../../redux/reducers/alert/alert";

import CheckoutForm from "./CheckoutForm";

const PaymentMethod: React.FC<{
  planId?: string;
  startDate?: string;
  durationInMonth?: string;
  contractorId?: string;
  contractId?: string;
  prepaymentAmount?: string;
}> = ({
  planId,
  startDate,
  durationInMonth,
  contractorId,
  contractId,
  prepaymentAmount,
}) => {
  const [promoCode, setPromoCode] = useState<string>("");
  const [promoCodeValue, setPromoCodeValue] = useState<string | undefined>(
    undefined
  );

  const stripe = useStripe();
  const dispatch = useAppDispatch();

  const paymentRequest = usePaymentRequest({
    options: {
      country: "US",
      currency: "usd",
      total: {
        label: "Verifying",
        amount: 0,
      },
    },
    onPaymentMethod: async ({
      complete,
      paymentMethod,
    }: {
      complete: any;
      paymentMethod: any;
      data: any;
    }) => {
      if (
        planId &&
        startDate &&
        durationInMonth &&
        paymentMethod &&
        contractorId &&
        contractId
      ) {
        dispatch(
          addSubscriptions({
            paymentMethodId: paymentMethod?.id,
            planId,
            startDate,
            durationInMonth: +durationInMonth,
            contractorId,
            contractId,
            promoCode: promoCodeValue,
            prepaymentAmount: prepaymentAmount,
          })
        );
      } else {
        dispatch(
          setAlert({
            type: "error",
            message: "missing all required parameters",
          })
        );
      }
    },
    stripe,
  });

  return (
    <CheckoutForm
      paymentRequest={paymentRequest}
      promoCode={promoCode}
      setPromoCode={setPromoCode}
      promoCodeValue={promoCodeValue}
      setPromoCodeValue={setPromoCodeValue}
    />
  );
};

export default PaymentMethod;
