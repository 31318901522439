import { createSlice } from "@reduxjs/toolkit";
import {
  getUserPaymentAccounts,
  getUserPaymentMethods,
  getActivePlanWithContractor,
  setDefaultPaymentMethod,
  getTaxes,
} from "../../actions/user";
import { removePaymentMethod, addPaymentMethod } from "../../actions/payment";
import {
  payment_method_type_bank,
  payment_method_type_card,
} from "../../../helpers/constants";
import {
  PaymentsAccountsDataT,
  PaymentsMethodsT,
  GetPaymentsMethodsPayloadT,
} from "./user.types";
import { CardT, BankT } from "../../../types/paymentsTypes";
import { SubscriptionsPlanDataT } from "../../../types/subscriptionsTypes";
import { defaultState } from "./user.defaultState";
import { TaxT } from "../../../types/userTypes";

const userSlice = createSlice({
  name: "user",
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserPaymentAccounts.pending.toString(), (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getUserPaymentAccounts.fulfilled.toString(),
      (
        state,
        action: { type: string; payload: GetPaymentsMethodsPayloadT }
      ) => {
        //@ts-ignore
        let card = [];
        //@ts-ignore
        let bank_account = [];

        if (action.payload?.data?.length) {
          action.payload?.data?.forEach((method) => {
            if (method.type === payment_method_type_bank) {
              //@ts-ignore
              bank_account = [...bank_account, method];
            }
            if (method.type === payment_method_type_card) {
              //@ts-ignore
              card = [...card, method];
            }
          });
        }

        state.isLoading = false;
        state.paymentsAccounts = {
          data: {
            //@ts-ignore
            bank_account: bank_account.sort(
              (a, b) => Number(b.is_default) - Number(a.is_default)
            ),
            //@ts-ignore
            card: card.sort(
              (a, b) => Number(b.is_default) - Number(a.is_default)
            ),
          },
          isInitiated: true,
        };
      }
    );
    builder.addCase(getUserPaymentAccounts.rejected.toString(), (state) => {
      state.isLoading = false;
      state.paymentsAccounts = {
        isInitiated: true,
        data: { ...state.paymentsAccounts.data },
      };
    });
    builder.addCase(getUserPaymentMethods.pending.toString(), (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getUserPaymentMethods.fulfilled.toString(),
      (state, action: { type: string; payload: PaymentsMethodsT }) => {
        state.isLoading = false;
        state.paymentsMethods.data = action.payload.data;
      }
    );
    builder.addCase(getUserPaymentMethods.rejected.toString(), (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      removePaymentMethod.fulfilled.toString(),
      (
        state,
        action: {
          type: string;
          payload: { payment_method_id: string };
        }
      ) => {
        if (
          state.paymentsAccounts.data?.card?.length &&
          action.payload.payment_method_id
        ) {
          const cards = [...state.paymentsAccounts?.data?.card].filter(
            (card) => card.id !== action.payload.payment_method_id
          );

          state.paymentsAccounts = {
            ...state.paymentsAccounts,
            data: {
              ...state.paymentsAccounts.data,
              card: cards,
            },
          };
        }
        if (
          state.paymentsAccounts.data?.bank_account?.length &&
          action.payload.payment_method_id
        ) {
          const banks = [...state.paymentsAccounts?.data?.bank_account].filter(
            (bank) => bank.id !== action.payload.payment_method_id
          );
          state.paymentsAccounts = {
            ...state.paymentsAccounts,
            data: {
              ...state.paymentsAccounts.data,
              bank_account: banks,
            },
          };
        }
      }
    );
    builder.addCase(
      addPaymentMethod.fulfilled.toString(),
      (
        state,
        action: {
          type: string;
          payload: {
            data: CardT | BankT;
            method: string;
          };
        }
      ) => {
        if (action.payload.method === "card") {
          const cardsList = state.paymentsAccounts?.data?.card || [];
          const cardsListWithFirstDefaultMethodList = [
            action.payload.data,
            ...cardsList,
          ].sort((a, b) => Number(b.is_default) - Number(a.is_default));

          state.paymentsAccounts = {
            ...state.paymentsAccounts,
            data: {
              ...state.paymentsAccounts.data,
              //@ts-ignore
              card: [...cardsListWithFirstDefaultMethodList],
            },
          };
        } else {
          const bankAccountList =
            state.paymentsAccounts?.data?.bank_account || [];
          state.paymentsAccounts = {
            ...state.paymentsAccounts,
            data: {
              ...state.paymentsAccounts.data,
              bank_account: [
                //@ts-ignore
                action.payload.data,
                //@ts-ignore
                ...bankAccountList,
              ],
            },
          };
        }
      }
    );
    builder.addCase(getActivePlanWithContractor.pending.toString(), (state) => {
      state.activeSubscription = {
        isLoading: true,
      };
    });

    builder.addCase(
      getActivePlanWithContractor.fulfilled.toString(),
      (
        state,
        action: {
          type: string;
          payload: { data: SubscriptionsPlanDataT };
        }
      ) => {
        state.activeSubscription = {
          data: action.payload.data,
          isLoading: false,
          isInitiated: true,
        };
      }
    );
    builder.addCase(
      getActivePlanWithContractor.rejected.toString(),
      (state) => {
        state.activeSubscription = {
          isLoading: false,
          isInitiated: true,
        };
      }
    );
    builder.addCase(setDefaultPaymentMethod.pending.toString(), (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      setDefaultPaymentMethod.fulfilled.toString(),
      (state, action: { type: string; payload: { id: string } }) => {
        const banks = state.paymentsAccounts?.data?.bank_account?.length
          ? [...state.paymentsAccounts?.data?.bank_account].map(
              (bank: BankT) => {
                return bank.id === action.payload.id
                  ? { ...bank, is_default: true }
                  : { ...bank, is_default: false };
              }
            )
          : [];

        const cards = state.paymentsAccounts?.data?.card?.length
          ? [...state.paymentsAccounts?.data?.card]
              .map((card: CardT) => {
                return card.id === action.payload.id
                  ? { ...card, is_default: true }
                  : { ...card, is_default: false };
              })
              .sort((a, b) => Number(b.is_default) - Number(a.is_default))
          : [];

        state.isLoading = false;
        state.paymentsAccounts.data = {
          bank_account: banks,
          card: cards,
        };
      }
    );
    builder.addCase(setDefaultPaymentMethod.rejected.toString(), (state) => {
      state.isLoading = false;
    });
    builder.addCase(getTaxes.pending.toString(), (state) => {
      state.taxesData.isLoading = true;
    });
    builder.addCase(
      getTaxes.fulfilled.toString(),
      (state, action: { payload: Array<TaxT>; type: string }) => {
        state.taxesData = {
          taxesList: action.payload,
          isLoading: false,
          isInitiated: true,
        };
      }
    );
    builder.addCase(getTaxes.rejected.toString(), (state) => {
      state.taxesData = {
        taxesList: [],
        isLoading: false,
        isInitiated: true,
      };
    });
  },
});
const userReducer = userSlice.reducer;

export const {} = userSlice.actions;
export default userReducer;
