import React, { useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/store";

import styles from "./styles.module.scss";
import history from "../../utils/history";
import { getTaxes } from "../../redux/actions/user";
import { TaxStatusT } from "../../types/userTypes";
import { isFloat, isInt } from "../../helpers/functionsHelpers";
import LoaderAbsolute from "../../Components/LoaderAbsolute/LoaderAbsolute";

const TaxesScreen = () => {
  const params = new URLSearchParams(window.location.search);
  const limit = params.get("limit");

  const dispatch = useAppDispatch();
  const { taxesData } = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getTaxes());
  }, []);

  const renderAmountCell = ({
    amount,
    currency,
  }: {
    amount: number | null;
    currency: string;
  }) => {
    if (!amount) return;
    if (isInt(amount)) {
      return (
        <>
          <div>{amount}.00</div>
          <div>{currency}</div>
        </>
      );
    }
    if (isFloat(amount)) {
      <>
        <div>{amount}</div>
        <div>{currency}</div>
      </>;
    }
  };

  const renderStatusCell = (status: TaxStatusT) => {
    if (status === "paid") {
      return <div className={styles.paid}>paid</div>;
    }
    return <div className={styles.unpaid}>pending</div>;
  };

  const taxesListToRender = limit
    ? taxesData?.taxesList.slice(0, +limit)
    : taxesData.taxesList;

  return (
    <Grid className={styles.wrapper} container flexDirection="column">
      <Grid className={styles.container}>
        <Table>
          <TableHead>
            <TableRow className={styles.header}>
              <TableCell
                className={`${styles.headerTitle} ${styles.headerNameCell}`}
                align={"center"}
                size={"small"}
              >
                Name
              </TableCell>
              <TableCell
                className={`${styles.headerTitle} ${styles.headerSumCell}`}
                align={"center"}
              >
                Total
              </TableCell>
              <TableCell
                className={`${styles.headerTitle} ${styles.headerDateCell}`}
                align={"center"}
              >
                Due date
              </TableCell>
              <TableCell
                className={`${styles.headerTitle} ${styles.headerStatusCell}`}
                align={"center"}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxesListToRender?.length ? (
              taxesListToRender?.map((tax, index) => (
                <TableRow key={tax.id}>
                  <TableCell
                    className={styles.nameCell}
                    align={"center"}
                    size={"small"}
                  >
                    {tax.tax.title}
                  </TableCell>
                  <TableCell align={"center"} className={styles.sumCell}>
                    {renderAmountCell({
                      amount: tax.amount,
                      currency: tax.currency,
                    })}
                  </TableCell>
                  <TableCell align={"center"} className={styles.dateCell}>
                    {moment(tax.deadline).format("MMM DD,YYYY")}
                  </TableCell>
                  <TableCell align={"center"} className={styles.statusCell}>
                    {renderStatusCell(tax.status)}
                  </TableCell>
                </TableRow>
              ))
            ) : taxesData?.isInitiated ? (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                className={styles.emptyList}
              >
                <img src={require("../../assets/images/emojiSad.png")} />
                <p>
                  Sorry, taxes haven’t been added to your account yet. It may
                  take some time to set up this for you
                </p>
              </Grid>
            ) : null}
          </TableBody>
        </Table>
      </Grid>
      {taxesData.isLoading && <LoaderAbsolute />}
    </Grid>
  );
};

export default TaxesScreen;
