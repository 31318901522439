import { ProcessPaymentErrorT } from "../types/paymentsTypes";
import {
  BuySubscriptionErrorT,
  UpdateSubscriptionErrorT,
  ActivePlanWithContractorErrorT,
  RemoveBankErrorT,
} from "../types/subscriptionsTypes";

export const paymentProcessErrorHandler = (payload: ProcessPaymentErrorT) => {
  return (
    (payload?.errors?.method?.length && payload?.errors?.method[0]) ||
    (payload?.errors?.accountId?.length && payload?.errors?.accountId[0]) ||
    (payload?.errors?.amount?.length && payload?.errors?.amount[0]) ||
    (payload?.errors?.currency?.length && payload?.errors?.currency[0]) ||
    (payload?.errors?.invoiceId?.length && payload?.errors?.invoiceId[0]) ||
    (payload?.errors?.type?.length && payload?.errors?.type[0]) ||
    payload?.message ||
    "Something went wrong"
  );
};

export const bySubscriptionErrorHandler = (payload: BuySubscriptionErrorT) => {
  return (
      (payload?.errors?.prepaymentAmount?.length && payload?.errors?.prepaymentAmount[0]) ||
    (payload?.errors?.startDate?.length && payload?.errors?.startDate[0]) ||
    (payload?.errors?.planId?.length && payload?.errors?.planId[0]) ||
    (payload?.errors?.paymentMethodId?.length &&
      payload?.errors?.paymentMethodId[0]) ||
    (payload?.errors?.durationInMonth?.length &&
      payload?.errors?.durationInMonth[0]) ||
    (payload?.errors?.contractorId?.length &&
      payload?.errors?.contractorId[0]) ||
    (payload?.errors?.contractId?.length && payload?.errors?.contractId[0]) ||
    (payload?.errors?.promoCode?.length && payload?.errors?.promoCode[0]) ||
    payload?.message ||
    "Something went wrong"
  );
};

export const updateActivePlanErrorHandler = (
  payload: UpdateSubscriptionErrorT
) => {
  return (
    (payload?.errors?.startDate?.length && payload?.errors?.startDate[0]) ||
    (payload?.errors?.planId?.length && payload?.errors?.planId[0]) ||
    payload?.message ||
    "Something went wrong"
  );
};

export const getActivePlanWithContractorErrorHandler = (
  payload: ActivePlanWithContractorErrorT
) => {
  return (
    (payload?.errors?.contractorId?.length &&
      payload?.errors?.contractorId[0]) ||
    (payload?.errors?.contractId?.length && payload?.errors?.contractId[0]) ||
    payload?.message ||
    "Something went wrong"
  );
};

export const removeCardErrorHandler = (payload: RemoveBankErrorT) => {
  return (
    (payload?.errors?.payment_method_id?.length &&
      payload?.errors?.payment_method_id[0]) ||
    payload?.message ||
    "Something went wrong"
  );
};
