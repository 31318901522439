import React from "react";
import { Grid } from "@mui/material";

import { BankT } from "../../types/paymentsTypes";

import styles from "./styles.module.scss";

const BankValues: React.FC<{
  bankData: BankT;
}> = ({ bankData }) => {
  return (
    <Grid container flexDirection={"column"} className={styles.bankAccountData}>
      <Grid item className={styles.row}>
        <p className={styles.label}>Account holder:</p>
        <p className={styles.value}>
          "{bankData?.us_bank_account?.account_holder_type}"
        </p>
      </Grid>
      <Grid item className={styles.row}>
        <p className={styles.label}>Account type:</p>
        <p className={styles.value}>
          "{bankData?.us_bank_account?.account_type}"
        </p>
      </Grid>
      <Grid item className={styles.row}>
        <p className={styles.label}>Bank:</p>
        <p className={styles.value}>{bankData?.us_bank_account?.bank_name}</p>
      </Grid>
      <Grid item className={styles.row}>
        <p className={styles.label}>Routing number:</p>
        <p className={styles.value}>
          {bankData?.us_bank_account?.routing_number}
        </p>
      </Grid>
      <Grid item className={styles.row}>
        <p className={styles.label}>Last four digits:</p>
        <p className={styles.value}>{bankData?.us_bank_account?.last4}</p>
      </Grid>
    </Grid>
  );
};

export default BankValues;
