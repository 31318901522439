import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getMethods,
  paymentProcess,
  addPaymentMethod,
  removePaymentMethod,
  webhookSuccessRequest,
} from "../../actions/payment";
import {
  getUserPaymentAccounts,
  getActivePlanWithContractor,
  setDefaultPaymentMethod,
} from "../../actions/user";
import {
  addSubscriptions,
  cancelSubscription,
  getAllSubscriptionsList,
  getPlansList,
  updateActivePlan,
  getPromoCodeData,
} from "../../actions/subscriptions";

import {
  paymentProcessErrorHandler,
  bySubscriptionErrorHandler,
  updateActivePlanErrorHandler,
  getActivePlanWithContractorErrorHandler,
  removeCardErrorHandler,
} from "../../../helpers/reducerErrors";
import { AlertType } from "./alert.type";
import { ProcessPaymentErrorT } from "../../../types/paymentsTypes";
import {
  BuySubscriptionErrorT,
  RemoveBankErrorT,
} from "../../../types/subscriptionsTypes";

const defaultState: AlertType = {
  open: false,
  type: "success",
  message: "",
  length: 5000,
};

const alertSlice = createSlice({
  name: "alert",
  initialState: defaultState,
  reducers: {
    setAlert: (state, { payload }: PayloadAction<any>) => {
      state.open = true;
      state.type = payload.type;
      state.message = payload.message;
      state.length = +payload.time >= 0 ? payload.time : 5000;
    },
    closeAlert: (state) => {
      state.open = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      addPaymentMethod.fulfilled.toString(),
      (
        state,
        action: { payload: { message: string; type?: "update" }; type: string }
      ) => {
        state.open = true;
        state.type = "success";
        state.message =
          action.payload?.type === "update"
            ? "Bank account has been successfully updated"
            : action.payload?.message;
      }
    );
    builder.addCase(
      addPaymentMethod.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = action.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(
      getMethods.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = action.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(
      paymentProcess.rejected.toString(),
      (
        state,
        action: {
          payload: ProcessPaymentErrorT;
          type: string;
        }
      ) => {
        state.open = true;
        state.type = "error";
        state.message = paymentProcessErrorHandler(action.payload);
      }
    );
    builder.addCase(
      getUserPaymentAccounts.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = action.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(
      removePaymentMethod.fulfilled.toString(),
      (
        state,
        action: {
          payload: { message: string; publicToken?: string };
          type: string;
        }
      ) => {
        if (!action.payload?.publicToken) {
          state.open = true;
          state.type = "success";
          state.message = action.payload?.message;
        }
      }
    );
    builder.addCase(
      removePaymentMethod.rejected.toString(),
      (state, action: { payload: RemoveBankErrorT; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = removeCardErrorHandler(action.payload);
      }
    );
    builder.addCase(
      addSubscriptions.rejected.toString(),
      (state, action: { payload: BuySubscriptionErrorT; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = bySubscriptionErrorHandler(action.payload);
      }
    );
    builder.addCase(
      cancelSubscription.fulfilled.toString(),
      (
        state,
        action: {
          payload: any;
          type: string;
        }
      ) => {
        state.open = true;
        state.type = "success";
        state.message = action.payload?.message;
      }
    );
    builder.addCase(
      cancelSubscription.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = action.payload?.message;
      }
    );
    builder.addCase(
      getAllSubscriptionsList.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = action.payload?.message;
      }
    );
    builder.addCase(
      getPlansList.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = action.payload?.message;
      }
    );
    builder.addCase(
      getActivePlanWithContractor.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = getActivePlanWithContractorErrorHandler(action.payload);
      }
    );
    builder.addCase(
      updateActivePlan.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = updateActivePlanErrorHandler(action.payload);
      }
    );
    builder.addCase(
      getPromoCodeData.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        if (action.payload?.message?.length) {
          state.open = true;
          state.type = "error";
          state.message = action.payload?.message;
        }
      }
    );
    builder.addCase(
      setDefaultPaymentMethod.fulfilled.toString(),
      (
        state,
        action: {
          payload: { message: string };
          type: string;
        }
      ) => {
        state.open = true;
        state.type = "success";
        state.message = action.payload?.message;
      }
    );
    builder.addCase(
      setDefaultPaymentMethod.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = action.payload?.message;
      }
    );
    builder.addCase(
      webhookSuccessRequest.rejected.toString(),
      (state, action: { payload: { message: string }; type: string }) => {
        state.open = true;
        state.type = "error";
        state.message = action.payload?.message;
      }
    );
  },
});

const alertReducer = alertSlice.reducer;

export const { setAlert, closeAlert } = alertSlice.actions;
export default alertReducer;

export const alertShowThunk =
  (type: string, message: string, time?: number): any =>
  async (dispatch: any) => {
    const alertData = {
      type: type,
      message: message,
      time,
    };
    dispatch(setAlert(alertData));
  };

export const alertCloseThunk = () => async (dispatch: any) => {
  dispatch(closeAlert());
};
