import React, { memo } from "react";
import moment from "moment";

import Button from "../Button/Button";
import SuccessIcon from "../../assets/images/success.svg";
import { PlanT } from "../../types/subscriptionsTypes";
import styles from "./styles.module.scss";

type PropsT = {
  data: PlanT;
  onSubmitPlan?: (id: string, amount: string) => void;
  isOnlyViewSubscriptions?: boolean;
  isDisabled?: boolean;
  isUpdatePlan?: boolean;
  isCurrent?: boolean;
  futureStartDate?: string | null;
  isDetailView?: boolean;
};

const SubscriptionPlan: React.FC<PropsT> = memo(
  ({
    data,
    onSubmitPlan,
    isOnlyViewSubscriptions,
    isDisabled,
    isUpdatePlan,
    isCurrent,
    futureStartDate,
    isDetailView,
  }) => {
    const onSubmitClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onSubmitPlan && onSubmitPlan(data.id, data.amount);
    };

    return (
      <div
        className={`${styles.main} ${!isDisabled && styles.active} ${
          isCurrent && styles.current
        } ${isOnlyViewSubscriptions && styles.smallPadding} ${
          isDetailView && styles.headerWithAutoHeight
        }`}
      >
        {futureStartDate && (
          <div className={styles.bottomLabel}>
            <img src={require("../../assets/images/IconStart.png")} />
            <p>Starts on: {moment(futureStartDate).format("MM:DD:YYYY")}</p>
          </div>
        )}
        <div className={styles.header}>
          <h2 className={styles.planName}>{data.name}</h2>
          <div className={styles.headerBottomBox}>
            <p className={styles.amount}>
              {data?.currency === "usd" ? "$" : "€"}
              {data.amount}
            </p>
            <div className={styles.headerBottomRightBox}>
              <p>per month</p>
              <p>per contract</p>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.bodyHeader}>
            <h5>Features:</h5>
            <p>{data?.description}</p>
          </div>
          <div className={styles.featuresList}>
            {data?.features.map((feature) => (
              <div className={styles.feature} key={feature.id}>
                <img src={SuccessIcon} />
                <p>{feature.label}</p>
              </div>
            ))}
          </div>
        </div>
        {!isOnlyViewSubscriptions && (
          <div className={styles.buttonWrapper}>
            <Button
              text={
                isUpdatePlan
                  ? isCurrent
                    ? "CURRENT PLAN"
                    : "UPDATE PLAN"
                  : "GET STARTED"
              }
              onClick={onSubmitClick}
              disabled={isDisabled}
            />
          </div>
        )}
      </div>
    );
  }
);

export default SubscriptionPlan;
