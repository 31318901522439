import React from "react";
//layout
import Layout from "./layouts/Layout";
//pages
import PaymentMethods from "./pages/PaymentMethods/PaymentMethods";
import NewCard from "./pages/NewCard/NewCard";
import PaymentIntent from "./pages/PaymentIntent/PaymentIntent";
import Subscription from "./pages/Subscription/Subscription";
import SubscriptionDetails from "./pages/SubscriptionDetails/SubscriptionDetails";
import SuccessScreen from "./pages/SuccessScreen/SuccessScreen";
import ActiveSubscriptions from "./pages/ActiveSubscriptions/ActiveSubscriptions";
import AddPaymentMethod from "./pages/AddPaymentMethod/AddPaymentMethod";
import PaymentInvoice from "./pages/PaymentInvoice/PaymentInvoice";
import TaxesScreen from "./pages/TaxesScreen/TaxesScreen";

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <PaymentMethods />,
      },
      {
        path: "add_payment_method",
        element: <AddPaymentMethod />,
      },
      {
        path: "payment_invoice",
        element: <PaymentInvoice />,
      },
      {
        path: "new_card",
        element: <NewCard />,
      },
      {
        path: "status",
        element: <PaymentIntent />,
      },
      {
        path: "subscription",
        element: <Subscription />,
      },
      {
        path: "subscription_details/:id",
        element: <SubscriptionDetails />,
      },
      {
        path: "success_screen",
        element: <SuccessScreen />,
      },
      {
        path: "active_subscriptions",
        element: <ActiveSubscriptions />,
      },
      {
        path: "taxes",
        element: <TaxesScreen />,
      },
    ],
  },
];

export default routes;
