import { createSlice } from "@reduxjs/toolkit";

import {
  getPlansList,
  getPlanDetails,
  addSubscriptions,
  setupIntent,
  getAllSubscriptionsList,
  cancelSubscription,
  updateActivePlan,
  getPromoCodeData,
} from "../../actions/subscriptions";
import {
  PlanT,
  PaymentIntentData,
  GetAllSubscriptionsListPayloadT,
  PromoCodeDataT,
} from "../../../types/subscriptionsTypes";
import { defaultState } from "./subscriptions.defaultState";

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: defaultState,
  reducers: {
    cleanPromoCodeDataState: (state) => {
      state.promoCodeData = {
        isLoading: false,
      };
    },
    cleanCancelSubscriptionState: (state) => {
      state.cancelSubscriptionsState = {
        isLoading: false,
      };
    },
    cleanUpdateActivePlanState: (state) => {
      state.updateActivePlanState = {
        isLoading: false,
      };
    },
    cleanPaymentIntentState: (state) => {
      state.paymentIntentState = { isLoading: true };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlansList.pending.toString(), (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getPlansList.fulfilled.toString(),
      (state, action: { type: string; payload: Array<PlanT> }) => {
        state.isLoading = false;
        state.plansList = action.payload;
      }
    );
    builder.addCase(getPlansList.rejected.toString(), (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPlanDetails.pending.toString(), (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getPlanDetails.fulfilled.toString(),
      (state, action: { type: string; payload: PlanT }) => {
        state.isLoading = false;
        state.planDetails = action.payload;
      }
    );
    builder.addCase(getPlanDetails.rejected.toString(), (state) => {
      state.isLoading = false;
    });
    builder.addCase(addSubscriptions.pending.toString(), (state) => {
      state.buySubscriptionState = { isLoading: true };
    });
    builder.addCase(addSubscriptions.fulfilled.toString(), (state) => {
      state.isLoading = false;
      state.buySubscriptionState = { success: true, isLoading: false };
    });
    builder.addCase(addSubscriptions.rejected.toString(), (state) => {
      state.isLoading = false;
      state.buySubscriptionState = { success: false, isLoading: false };
    });
    builder.addCase(setupIntent.pending.toString(), (state) => {
      state.paymentIntentState = { isLoading: true };
    });
    builder.addCase(
      setupIntent.fulfilled.toString(),
      (state, action: { type: string; payload: PaymentIntentData }) => {
        state.paymentIntentState = {
          success: true,
          isLoading: false,
          data: action.payload,
        };
      }
    );
    builder.addCase(setupIntent.rejected.toString(), (state) => {
      state.isLoading = false;
      state.paymentIntentState = { success: false, isLoading: false };
    });
    builder.addCase(getAllSubscriptionsList.pending.toString(), (state) => {
      state.allSubscriptionsList = {
        ...state.allSubscriptionsList,
        isLoading: true,
      };
    });
    builder.addCase(
      getAllSubscriptionsList.fulfilled.toString(),
      (
        state,
        action: {
          type: string;
          payload: GetAllSubscriptionsListPayloadT;
        }
      ) => {
        state.allSubscriptionsList = { isLoading: false, ...action.payload };
      }
    );
    builder.addCase(getAllSubscriptionsList.rejected.toString(), (state) => {
      state.allSubscriptionsList = { isLoading: false };
    });
    builder.addCase(cancelSubscription.pending.toString(), (state) => {
      state.cancelSubscriptionsState = { isLoading: true };
    });
    builder.addCase(
      cancelSubscription.fulfilled.toString(),
      (state, action: { type: string; payload: { id: number } }) => {
        let changedSubscriptionsList = state?.allSubscriptionsList?.data?.map(
          (subscription) => {
            if (subscription.id === action.payload.id) {
              return { ...subscription, status: "canceled" };
            }
            return subscription;
          }
        );
        state.cancelSubscriptionsState = { isLoading: false, success: true };
        state.allSubscriptionsList = {
          ...state.allSubscriptionsList,
          data: changedSubscriptionsList,
        };
      }
    );
    builder.addCase(cancelSubscription.rejected.toString(), (state) => {
      state.cancelSubscriptionsState = { isLoading: false, success: false };
    });
    builder.addCase(updateActivePlan.pending.toString(), (state) => {
      state.updateActivePlanState = { isLoading: true };
    });
    builder.addCase(updateActivePlan.fulfilled.toString(), (state) => {
      state.updateActivePlanState = { isLoading: false, success: true };
    });
    builder.addCase(updateActivePlan.rejected.toString(), (state) => {
      state.updateActivePlanState = { isLoading: false, success: false };
    });
    builder.addCase(getPromoCodeData.pending.toString(), (state) => {
      state.promoCodeData.isLoading = true;
    });
    builder.addCase(
      getPromoCodeData.fulfilled.toString(),
      (state, action: { type: string; payload: PromoCodeDataT }) => {
        state.promoCodeData = {
          data: action?.payload,
          isLoading: false,
          success: true,
        };
      }
    );
    builder.addCase(getPromoCodeData.rejected.toString(), (state) => {
      state.promoCodeData.isLoading = false;
      state.promoCodeData.success = false;
    });
  },
});
const subscriptionsReducer = subscriptionsSlice.reducer;

export const {
  cleanCancelSubscriptionState,
  cleanUpdateActivePlanState,
  cleanPromoCodeDataState,
  cleanPaymentIntentState,
} = subscriptionsSlice.actions;
export default subscriptionsReducer;
