import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";

import PaymentSuccess from "../../Components/PaymentSuccess/PaymentSuccess";
import PaymentFailed from "../../Components/PaymentFailed/PaymentFailed";
import LoaderAbsolute from "../../Components/LoaderAbsolute/LoaderAbsolute";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/store";
import { webhookSuccessRequest } from "../../redux/actions/payment";
import { cleanWebhookSuccessRequestState } from "../../redux/reducers/payments/payments";
import { PaymentIntentStatusT } from "../../types/paymentsTypes";

const PaymentStatus: React.FC = () => {
  const stripe = useStripe();

  const dispatch = useAppDispatch();

  const { webhookSuccessRequestState } = useAppSelector(
    (state: RootState) => state.payments
  );

  const [status, setStatus] = useState<PaymentIntentStatusT>(null);
  const params = new URLSearchParams(window.location.search);
  const invoiceId = params.get("invoiceId") || "";
  const transactionId = params.get("transactionId") || "";
  const webhookSuccessRequested = params.get("webhookSuccessRequested") || "";
  const statusParams = params.get("status") || "";

  useEffect(() => {
    if (
      transactionId &&
      invoiceId &&
      !webhookSuccessRequestState?.success &&
      !webhookSuccessRequested
    ) {
      dispatch(
        webhookSuccessRequest({
          invoiceId,
          success: true,
          message: "Payment successfully initiate",
          status: "pending",
          backendTransactionId: transactionId,
        })
      );
    }
    return () => {
      dispatch(cleanWebhookSuccessRequestState());
    };
  }, []);

  useEffect(() => {
    if (statusParams === "succeeded") {
      setStatus(statusParams);
      return;
    }
    getPaymentIntentStatus();
  }, [stripe]);

  const getPaymentIntentStatus = () => {
    if (!stripe) {
      return;
    }
    const clientSecret = params.get("payment_intent_client_secret") || "";
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }: any) => {
        if (paymentIntent.status === "processing") {
          setTimeout(() => {
            getPaymentIntentStatus();
          }, 2000);
        }
        setStatus(paymentIntent.status);
      });
  };

  const renderStatusComponent = (status: PaymentIntentStatusT) => {
    switch (status) {
      case "succeeded":
        return <PaymentSuccess />;
      case "requires_payment_method":
        return <PaymentFailed />;
      default:
        return <LoaderAbsolute />;
    }
  };

  return (
    <Grid container justifyContent={"center"} alignItems="center" p={4}>
      <Grid item xs={12}>
        {renderStatusComponent(status)}
      </Grid>
    </Grid>
  );
};

export default PaymentStatus;
