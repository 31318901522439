import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import CustomRouter from "./utils/CustomRouter";
import history from "./utils/history";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <CustomRouter history={history}>
    <App />
  </CustomRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
