import React from "react";
import { Button } from "@mui/material";

import {
  payment_method_type_card,
  payment_method_type_bank,
} from "../../helpers/constants";
import { PaymentMethodsVariantsT } from "../../types/paymentsTypes";

import styles from "./styles.module.scss";

const PaymentMethodsSwitch: React.FC<{
  selectedSwitchValue: PaymentMethodsVariantsT;
  setCardSwitchValue: () => void;
  setBankSwitchValue: () => void;
}> = ({ selectedSwitchValue, setCardSwitchValue, setBankSwitchValue }) => {
  return (
    <div className={styles.switch}>
      <Button
        className={`${styles.switchItem} ${
          payment_method_type_card === selectedSwitchValue &&
          styles.activeSwitchItem
        }`}
        onClick={setCardSwitchValue}
      >
        <p>card</p>
      </Button>
      <Button
        className={`${styles.switchItem} ${
          payment_method_type_bank === selectedSwitchValue &&
          styles.activeSwitchItem
        }`}
        onClick={setBankSwitchValue}
      >
        <p>bank transfer</p>
      </Button>
    </div>
  );
};

export default PaymentMethodsSwitch;
