import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Grid } from "@mui/material";

import { stripeConfig } from "../../config";
import PaymentStatus from "./PaymentStatus";

import styles from "./styles.module.scss";
//@ts-ignore
const stripePromise = loadStripe(stripeConfig.publicKey);

const PaymentIntent = () => {
  return (
    <Grid className={styles.wrapper}>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <PaymentStatus />
        </Elements>
      )}
    </Grid>
  );
};

export default PaymentIntent;
