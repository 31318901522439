import React from "react";
import { Outlet } from "react-router-dom";

import { Grid } from "@mui/material";

import styles from "./styles.module.scss";

type PropsT = {
  children?: JSX.Element;
};

const Layout: React.FC<PropsT> = ({ children }) => {
  return (
    <Grid className={styles.root}>
      <Grid className={styles.appContent}>
        {children}
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Layout;
