import axios from "axios";
import { commonConfig } from "../config";

export const setHeader = () => {
  let token;

  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search);
    token = params.get("bearer");
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token && `Bearer ${token}`,
  };
};

const axiosInstance = axios.create({
  baseURL: `${commonConfig.apiHost}/api`,
  withCredentials: false,
  headers: setHeader(),
});

export default axiosInstance;
